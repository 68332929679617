import { createSlice } from '@reduxjs/toolkit';
import { ApiModel, normalize } from '@cyferd/client-engine';
import type { GeneralModel } from '@models';
import type { ViewState } from '@models/general';

export const getInitialState = (): ViewState => ({ view: null, timestamp: null });

export const areAppsEqual = (apps1: any, apps2: { id: string }[]): boolean => {
  if (!Array.isArray(apps1)) return false;
  if (apps1.length !== apps2.length) return false;
  return apps1.every((app, i) => app.id === apps2[i].id);
};

const viewSlice = createSlice({
  name: 'view',
  initialState: getInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(ApiModel.TriggerActionType.DISPATCH_SET_VIEW, (state: GeneralModel.ViewState = getInitialState(), action: GeneralModel.Action) => {
      if (state.view?.id !== action.payload?.id) {
        state.view = normalize.view(action.payload, true);
        state.timestamp = Date.now();
        return;
      }

      if (!Array.isArray(action.payload?.apps)) {
        state.view = { ...state.view, apps: [] };
        return;
      }

      if (!areAppsEqual(state?.view?.apps, action.payload.apps)) {
        state.view = { ...state.view, apps: action.payload.apps };
      }
    });
  }
});

export type ViewActions = typeof viewSlice.actions;

export const viewReducer = viewSlice.reducer;
