import { create } from 'zustand';
import { onlyOneIsTrue } from '@utils';

export enum SidebarNavStatus {
  HIDDEN = 'hidden',
  COLLAPSED = 'collapsed',
  EXPANDED = 'expanded'
}

interface NavigationMenuState {
  status: SidebarNavStatus;
  lastStatus: SidebarNavStatus;

  itemIds: Record<string, boolean>;
  openItem: (itemId: string) => void;
  closeItem: () => void;

  toggleSidebarMode: () => void;
  toggleSidebarVisibility: () => void;
}

export const useNavigationMenuStore = create<NavigationMenuState>(set => ({
  status: SidebarNavStatus.COLLAPSED,
  lastStatus: SidebarNavStatus.COLLAPSED,

  itemIds: {},
  openItem: itemId => set(state => ({ ...state, itemIds: onlyOneIsTrue(itemId, state.itemIds) })),
  closeItem: () => set(state => ({ ...state, itemIds: onlyOneIsTrue(null, state.itemIds) })),

  toggleSidebarMode: () => {
    set(state => {
      const newStatus = state.status === SidebarNavStatus.EXPANDED ? SidebarNavStatus.COLLAPSED : SidebarNavStatus.EXPANDED;

      return {
        status: newStatus,
        lastStatus: state.status === SidebarNavStatus.HIDDEN ? state.lastStatus : newStatus
      };
    });
  },

  toggleSidebarVisibility: () => {
    set(state => {
      const newStatus = state.status === SidebarNavStatus.HIDDEN ? state.lastStatus : SidebarNavStatus.HIDDEN;

      return {
        status: newStatus,
        lastStatus: state.status === SidebarNavStatus.HIDDEN ? state.lastStatus : state.status
      };
    });
  }
}));
