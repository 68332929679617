import { CLOSED_MENU_WIDTH, COLOR, FONT_SIZE, FONT_WEIGHT, GAP, MENU_WIDTH, SHADOW } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  container: css`
    display: block;
    position: relative;
    height: 100%;
    [data-selector='cta-label'] {
      text-decoration: none;
      font-weight: ${FONT_WEIGHT.NORMAL};
      font-size: ${FONT_SIZE.S};
      margin-left: ${GAP.M};
    }
  `,
  loadinSkeleton: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${GAP.M};
    max-width: 48px;
    margin: 0 auto;
    height: 250px;
  `,
  menu: css`
    z-index: 10;
    width: ${MENU_WIDTH};
    background-color: ${COLOR.BASE_BACKGROUND_ALT_1};
    box-shadow: ${SHADOW.S};
    display: flex;
    flex-direction: column;
    height: 100%;
    container-type: inline-size;
    transition: 0.3s ease;

    #APPS,
    #BUILDER {
      flex: 1;
      & > span {
        display: grid;
      }
    }
  `,
  menuHidden: css`
    width: 0;
    overflow: hidden;
  `,
  collapsedMenu: css`
    width: ${CLOSED_MENU_WIDTH};
    border-radius: 0;
    border-width: 0;
  `,
  content: css`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: ${GAP.XS};
  `,
  containerTab: css`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
  `,
  header: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-style: solid;
    border-color: ${COLOR.NEUTRAL_4};
    border-top-width: 1px;
    border-bottom-width: 1px;
    height: 36px;
    padding: 0 16px;
  `,
  headerCollapsed: css`
    justify-content: center;
    align-items: center;
    padding: 0;
  `,
  toggleBtnExpanded: css`
    margin-left: auto;
    margin-right: ${GAP.S};
  `,
  toggleBtnCollapsed: css`
    display: flex;
    flex-direction: column;
    margin: 6px 0;
  `,
  divider: css`
    width: 100%;
    border-top: 1px ${COLOR.NEUTRAL_4} solid;
    margin-bottom: ${GAP.M};
  `
};
