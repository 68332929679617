// istanbul ignore file
import React, { useCallback, useContext, useMemo, useState } from 'react';
import type { ApiModel } from '@cyferd/client-engine';
import { GeneralModel, ViewModel, noop, useTranslate } from '@cyferd/client-engine';
import { CTA, CTAType } from '@components/elements/CTA';
import { CyWrapperContext } from '@components/smart/CyWrapper';
import { Icon } from '@components/elements/Icon';
import { ItemList } from '@components/elements/ItemList';
import { Modal } from '@components/elements/Modal';
import { SyntaxEditor } from '@components/elements/SyntaxEditor';
import { Table } from '@components/elements/Table';
import type { Item } from '@components/elements/Table/types';
import { FONT_SIZE, TRANS } from '@constants';
import { useTableBody, useTableHeader } from '../../hooks/useTable';
import type { ErrorCode, ErrorDetail, Payload } from '../../types';
import { styles } from './styles';

export interface EditErrorModalProps {
  payload: Payload;
  onOverrideItems?: (list: any[]) => void;
  onCancel?: () => void;
  onGoBack?: () => void;
  value: ApiModel.ApiValue;
}

interface IErrorTypeUi {
  error: ErrorDetail[];
  head: Item[];
  body: any[][];
}

const categorizeErrors = (payload: Payload) => {
  const errorsByCode: Record<ErrorCode, ErrorDetail[]> = {
    DENIED: [],
    UPDATE_CONFLICT: [],
    VALIDATION_ERROR: [],
    OTHERS: [],
    NOT_FOUND: []
  };

  payload.failed.forEach(error => {
    const errorArray = errorsByCode[error.code] || errorsByCode['OTHERS'];
    const validCode = error.code in errorsByCode ? error.code : 'OTHERS';
    errorsByCode[validCode] = errorArray.concat(error);
  });

  return errorsByCode;
};

const determineUIState = (errorsByCode: Record<ErrorCode, ErrorDetail[]>) => {
  const uiState = {
    showModal: false,
    stepperModal: false,
    basicErrorsModal: false,
    advancedErrorsModal: false,
    deniedErrors: errorsByCode.DENIED?.length > 0,
    notFoundErrors: errorsByCode.NOT_FOUND?.length > 0,
    otherErrors: errorsByCode.OTHERS?.length > 0,
    updateConflicts: errorsByCode.UPDATE_CONFLICT?.length > 0,
    validationErrors: errorsByCode.VALIDATION_ERROR?.length > 0
  };

  const hasBasicErrors = uiState.deniedErrors || uiState.notFoundErrors || uiState.otherErrors;

  const hasAdvancedErrors = uiState.updateConflicts || uiState.validationErrors;

  if (hasBasicErrors && hasAdvancedErrors) {
    uiState.stepperModal = true;
  } else if (hasBasicErrors) {
    uiState.showModal = true;
    uiState.basicErrorsModal = true;
  } else if (hasAdvancedErrors) {
    uiState.showModal = true;
    uiState.advancedErrorsModal = true;
  }

  return uiState;
};

const DeniedHandler = ({ error, body, head }: IErrorTypeUi) => (
  <div>
    <div css={styles.title}>
      <Icon name="warning" size={FONT_SIZE.L} />
      <h1>Some changes could not be saved</h1>
    </div>
    <p css={styles.subTitle}>
      Changes for {error.length} {error.length === 1 ? 'record' : 'records'} were not saved as you no longer have update permissions for them.
    </p>
    <p css={styles.extraInfo}>Talk to your administrator if you believe you should have permission</p>
    <div css={styles.wrapperTable}>
      <Table body={body} head={head} />
    </div>
  </div>
);

const NotFoundHandler = ({ body, head }: Omit<IErrorTypeUi, 'error'>) => (
  <div>
    <div css={styles.title}>
      <Icon name="warning" size={FONT_SIZE.L} />
      <h1>Some changed records could not be found</h1>
    </div>
    <p css={styles.subTitle}>Some changes could not be saved as the following records can no longer be found:</p>
    <div css={styles.wrapperTable}>
      <Table body={body} head={head} />
    </div>
    <p css={styles.extraInfo}>This could be because the records were deleted or you no longer have access to them.</p>
  </div>
);

const OtherErrorsHandler = ({ error, body, head }: IErrorTypeUi) => (
  <div>
    <div css={styles.title}>
      <Icon name="error" size={FONT_SIZE.L} />
      <h1>Errors detected</h1>
    </div>
    <p css={styles.subTitle}>Some errors were detected. Please share with your administrator.</p>
    <div css={styles.wrapperTable}>
      <Table body={body} head={head} />
    </div>
    <SyntaxEditor
      label="report"
      expanded={true}
      onChange={noop}
      info={JSON.stringify(error.map(data => data.message).join(' | '))}
      language={GeneralModel.JSONSchemaFormat.JSON}
      value={JSON.stringify(
        error.map(data => data.details),
        null,
        2
      )}
    />
  </div>
);

const ConflictHandler = ({ error, body, head }: IErrorTypeUi) => (
  <div>
    <div css={styles.title}>
      <h1>Some records were updated while you were editing</h1>
    </div>
    <p css={styles.subTitle}>
      {error.length} {error.length === 1 ? 'record' : 'records'} you have edited were recently updated.
    </p>
    <p css={styles.extraInfo}>Please view the updated records below and check if you would like to continue with your changes or edit these values.</p>
    <div css={styles.wrapperTable}>
      <Table body={body} head={head} />
    </div>
  </div>
);

const ValidationErrorHandler = ({ error, body, head }: IErrorTypeUi) => (
  <div>
    <div css={styles.title}>
      <h1>Some changes require attention</h1>
    </div>
    <p css={styles.subTitle}>
      {error.length} {error.length === 1 ? 'record' : 'records'} have problems that need to be resolved in order to save them..
    </p>
    <p css={styles.extraInfo}>Please view the updated records below and check if you would like to continue with your changes or edit these values.</p>
    <div css={styles.wrapperTable}>
      <Table body={body} head={head} />
    </div>
    <SyntaxEditor
      label="report"
      expanded={true}
      onChange={noop}
      info={JSON.stringify(error.map(data => data.message).join(' | '))}
      language={GeneralModel.JSONSchemaFormat.JSON}
      value={JSON.stringify(
        error.map(data => data.details),
        null,
        2
      )}
    />
  </div>
);

const MakingHeadAndBodyTable = ({
  value,
  error,
  children
}: {
  value: EditErrorModalProps['value'];
  error: ErrorDetail[];
  children: (props: IErrorTypeUi) => React.ReactNode;
}) => {
  const { useParsers } = useContext(CyWrapperContext);
  const { parseList } = useParsers({ query: value?.query });

  const { head, items, definitionMap } = useMemo(
    () => parseList({ entity: value?.query, list: error.map(item => item.input) }),
    [parseList, error, value?.query]
  );

  const tableBody = useTableBody({
    items,
    isLoading: false,
    definitionMap,
    dropdownContainerId: '',
    schemaProperties: value.query?.schema?.properties,
    onClick: noop,
    hasRecordActions: false,
    parseRecordActions: noop
  });
  const tableHeaderItems = useTableHeader({
    head,
    cursor: value.query?.cursor,
    definitionMap,
    hideSorting: true,
    updateCursor: noop,
    isLoading: false,
    hasActions: false
  });

  return children({ head: tableHeaderItems, body: tableBody, error });
};

export const EditErrorModal = ({ payload, onOverrideItems, onCancel, onGoBack, value }: EditErrorModalProps) => {
  const [currentStep, setCurrentStep] = useState(1);
  const { translate } = useTranslate();
  const errorsByCode = categorizeErrors(payload);
  const uiState = determineUIState(errorsByCode);

  const handleOverride = useCallback(() => {
    const list = errorsByCode.UPDATE_CONFLICT.map(data => data.input);
    return onOverrideItems(list);
  }, [onOverrideItems, errorsByCode.UPDATE_CONFLICT]);

  const handleNext = () => setCurrentStep(currentStep + 1);
  const CTACancel = () => <CTA type={CTAType.LINK} label="Don't save my changes for these records" onClick={onCancel} testid="cta-cancel" />;

  const AdvanceFooter = ({ validationErrors, updateConflict }: { validationErrors: boolean; updateConflict: boolean }) => {
    if (updateConflict && validationErrors)
      return (
        <ItemList>
          <CTA type={CTAType.LINK} label="Go back to edit" testid="cta-goback" onClick={onGoBack} />
          <CTACancel />
          <CTA
            type={CTAType.PRIMARY}
            label={`${translate(TRANS.client.buttons.save)} ${errorsByCode.UPDATE_CONFLICT.length}`}
            disabled={true}
            testid="save-cta-override"
          />
        </ItemList>
      );
    if (updateConflict)
      return (
        <ItemList>
          <CTA type={CTAType.LINK} label="Go back to edit" testid="cta-goback" onClick={onGoBack} />
          <CTACancel />
          <CTA
            type={CTAType.PRIMARY}
            label={`${translate(TRANS.client.buttons.save)} ${errorsByCode.UPDATE_CONFLICT.length}`}
            onClick={handleOverride}
            testid="save-cta-override"
          />
        </ItemList>
      );
    return (
      <ItemList>
        <CTACancel />
        <CTA type={CTAType.PRIMARY} label="Go back to edit" onClick={onGoBack} testid="save-cta-override" />
      </ItemList>
    );
  };

  const renderFooter = () => {
    if (uiState.stepperModal) {
      if (currentStep === 2) {
        return <AdvanceFooter validationErrors={uiState.validationErrors} updateConflict={uiState.updateConflicts} />;
      } else {
        return (
          <ItemList>
            <CTA type={CTAType.PRIMARY} label="Ok" onClick={handleNext} testid="cta-ok" />
          </ItemList>
        );
      }
    } else if (uiState.advancedErrorsModal) {
      return <AdvanceFooter validationErrors={uiState.validationErrors} updateConflict={uiState.updateConflicts} />;
    } else {
      return (
        <ItemList>
          <CTA type={CTAType.PRIMARY} label="Ok" onClick={onCancel} testid="cta-ok" />
        </ItemList>
      );
    }
  };

  const renderContent = () => {
    if (uiState.stepperModal) {
      return (
        <>
          {currentStep === 1 && errorsByCode.NOT_FOUND.length > 0 && (
            <MakingHeadAndBodyTable value={value} error={errorsByCode.NOT_FOUND}>
              {({ head, body }) => <NotFoundHandler head={head} body={body} />}
            </MakingHeadAndBodyTable>
          )}
          {currentStep === 1 && errorsByCode.DENIED.length > 0 && (
            <MakingHeadAndBodyTable value={value} error={errorsByCode.DENIED}>
              {({ head, body, error }) => <DeniedHandler error={error} head={head} body={body} />}
            </MakingHeadAndBodyTable>
          )}
          {currentStep === 1 && errorsByCode.OTHERS.length > 0 && (
            <MakingHeadAndBodyTable value={value} error={errorsByCode.OTHERS}>
              {({ head, body, error }) => <OtherErrorsHandler error={error} head={head} body={body} />}
            </MakingHeadAndBodyTable>
          )}
          {currentStep === 2 && errorsByCode.UPDATE_CONFLICT.length > 0 && (
            <MakingHeadAndBodyTable value={value} error={errorsByCode.UPDATE_CONFLICT}>
              {({ head, body, error }) => <ConflictHandler error={error} head={head} body={body} />}
            </MakingHeadAndBodyTable>
          )}
          {currentStep === 2 && errorsByCode.VALIDATION_ERROR.length > 0 && (
            <MakingHeadAndBodyTable value={value} error={errorsByCode.VALIDATION_ERROR}>
              {({ head, body, error }) => <ValidationErrorHandler error={error} head={head} body={body} />}
            </MakingHeadAndBodyTable>
          )}
        </>
      );
    } else {
      return (
        <>
          {uiState.basicErrorsModal && errorsByCode.NOT_FOUND.length > 0 && (
            <MakingHeadAndBodyTable value={value} error={errorsByCode.NOT_FOUND}>
              {({ head, body }) => <NotFoundHandler head={head} body={body} />}
            </MakingHeadAndBodyTable>
          )}
          {uiState.basicErrorsModal && errorsByCode.DENIED.length > 0 && (
            <MakingHeadAndBodyTable value={value} error={errorsByCode.DENIED}>
              {({ head, body, error }) => <DeniedHandler error={error} head={head} body={body} />}
            </MakingHeadAndBodyTable>
          )}
          {uiState.basicErrorsModal && errorsByCode.OTHERS.length > 0 && (
            <MakingHeadAndBodyTable value={value} error={errorsByCode.OTHERS}>
              {({ head, body, error }) => <OtherErrorsHandler error={error} head={head} body={body} />}
            </MakingHeadAndBodyTable>
          )}
          {uiState.advancedErrorsModal && errorsByCode.UPDATE_CONFLICT.length > 0 && (
            <MakingHeadAndBodyTable value={value} error={errorsByCode.UPDATE_CONFLICT}>
              {({ head, body, error }) => <ConflictHandler error={error} head={head} body={body} />}
            </MakingHeadAndBodyTable>
          )}
          {uiState.advancedErrorsModal && errorsByCode.VALIDATION_ERROR.length > 0 && (
            <MakingHeadAndBodyTable value={value} error={errorsByCode.VALIDATION_ERROR}>
              {({ head, body, error }) => <ValidationErrorHandler error={error} head={head} body={body} />}
            </MakingHeadAndBodyTable>
          )}
        </>
      );
    }
  };

  return (
    <Modal open={true} type={ViewModel.ModalType.FULL_SCREEN} footer={renderFooter()}>
      <div data-testid="EditErrorModal" css={styles.container}>
        {renderContent()}
      </div>
    </Modal>
  );
};
