import _ from 'lodash';
import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import { FONT_WEIGHT } from '@constants';
import { useSearchStore } from '../../../store/SearchStore';
import { memo } from 'react';

interface HighlightedParagraphProps {
  text: string;
  styles?: SerializedStyles[] | SerializedStyles;
}

const HighlightedParagraphComponent = ({ text, styles }: HighlightedParagraphProps) => {
  const searchQuery = useSearchStore(state => state.searchQuery);

  if (!text) return null;

  const escapedSearchQuery = _.escapeRegExp(searchQuery);
  // "split" will return the search query because the regex is a "capture group" (parentheses)
  const parts = text.split(new RegExp(`(${escapedSearchQuery})`, 'gi'));

  return (
    <p css={styles}>
      {parts.map((part, index) =>
        part.toLowerCase() === searchQuery?.toLowerCase() ? (
          <span key={index} css={highlightStyles}>
            {part}
          </span>
        ) : (
          part
        )
      )}
    </p>
  );
};

const highlightStyles = css`
  font-weight: ${FONT_WEIGHT.BOLD};
`;

export const HighlightedParagraph = memo(HighlightedParagraphComponent);
