import { ItemMenu } from '../ItemMenu';
import { ItemMenuPopover } from '../ItemMenuPopover';
import { BUILDER_ICON, BUILDER_ROUTE, SECTION_NAME } from '@constants';
import { useCanAccessRoute } from '@utils';
import { useLocation } from 'react-router-dom';
import { useCallback, useEffect, useMemo } from 'react';
import { styles } from './styles';
import { BUILDER_ID } from '../../constants';
import { useTranslate } from '@cyferd/client-engine';
import { SubItemMenu } from '../../../SubItemMenu';
import { SideContent } from '../../components/SideContent';
import { useGetNavigateTo } from '../../../../hooks/useGetNavigateTo';
import { useSearchStore } from '../../../../../../store/SearchStore';
import { useNavigationMenuStore } from '../../../../../../store/NavigationMenuStore';

export const Builder = ({ isOpen, isBuilderActive }) => {
  const { pathname } = useLocation();
  const { translate } = useTranslate();

  const { getNavigateTo } = useGetNavigateTo();
  const canAccess = useCanAccessRoute();

  const searchQuery = useSearchStore(state => state.searchQuery);
  const setBuilderHasResults = useSearchStore(state => state.setBuilderHasResults);

  const isSelected = useNavigationMenuStore(state => state.itemIds[BUILDER_ID]);
  const closeItem = useNavigationMenuStore(state => state.closeItem);
  const openItem = useNavigationMenuStore(state => state.openItem);

  const onToggle = useCallback(id => (isSelected ? closeItem() : openItem(id)), [isSelected, closeItem, openItem]);

  const showMore = isSelected || !!searchQuery;

  const builderRoutes = useMemo(
    () =>
      [
        {
          id: BUILDER_ROUTE.TAG_EDITOR.ROOT,
          icon: BUILDER_ICON.APPS,
          title: translate(SECTION_NAME.APPS),
          onClick: getNavigateTo({ path: BUILDER_ROUTE.TAG_EDITOR.ROOT })
        },
        {
          id: BUILDER_ROUTE.ENTITY_EDITOR.ROOT,
          icon: BUILDER_ICON.DATA,
          title: translate(SECTION_NAME.DATA),
          onClick: getNavigateTo({ path: BUILDER_ROUTE.ENTITY_EDITOR.ROOT })
        },
        {
          id: BUILDER_ROUTE.FLOW_EDITOR.ROOT,
          icon: BUILDER_ICON.FLOWS,
          title: translate(SECTION_NAME.FLOWS),
          onClick: getNavigateTo({ path: BUILDER_ROUTE.FLOW_EDITOR.ROOT })
        },
        {
          id: BUILDER_ROUTE.VIEW_EDITOR.ROOT,
          icon: BUILDER_ICON.VIEWS,
          title: translate(SECTION_NAME.VIEWS),
          onClick: getNavigateTo({ path: BUILDER_ROUTE.VIEW_EDITOR.ROOT })
        },
        {
          id: BUILDER_ROUTE.INTEGRATION_EDITOR.ROOT,
          icon: BUILDER_ICON.INTEGRATIONS,
          title: translate(SECTION_NAME.INTEGRATIONS),
          onClick: getNavigateTo({ path: BUILDER_ROUTE.INTEGRATION_EDITOR.ROOT })
        },
        {
          id: BUILDER_ROUTE.DEVICE_MANAGER.ROOT,
          icon: BUILDER_ICON.DEVICE_MANAGER,
          title: translate(SECTION_NAME.DEVICE_MANAGER),
          onClick: getNavigateTo({ path: BUILDER_ROUTE.DEVICE_MANAGER.ROOT })
        },
        {
          id: BUILDER_ROUTE.INVITATION_EDITOR.ROOT,
          icon: BUILDER_ICON.INVITATIONS,
          title: translate(SECTION_NAME.INVITATIONS),
          onClick: getNavigateTo({ path: BUILDER_ROUTE.INVITATION_EDITOR.ROOT })
        },
        {
          id: BUILDER_ROUTE.USER_EDITOR.ROOT,
          icon: BUILDER_ICON.USERS,
          title: translate(SECTION_NAME.USERS),
          onClick: getNavigateTo({ path: BUILDER_ROUTE.USER_EDITOR.ROOT })
        }
      ]
        .filter(({ id }) => canAccess(id))
        .filter(({ title }) => title.toLowerCase().includes(searchQuery.toLowerCase())),
    [searchQuery, canAccess, getNavigateTo, translate]
  );

  const isItemActive = useCallback((id: string) => (id !== '/' ? pathname.startsWith(id) : pathname === id), [pathname]);

  useEffect(
    () => setBuilderHasResults(Boolean(builderRoutes.length) || 'builder'.includes(searchQuery.toLowerCase())),
    [searchQuery, builderRoutes, setBuilderHasResults]
  );

  if (!builderRoutes.length && !'builder'.includes(searchQuery.toLowerCase())) return null;

  return (
    <>
      <ItemMenuPopover
        content={
          <SideContent
            title="Builder"
            icon="grid_view"
            items={builderRoutes}
            isActive={isBuilderActive}
            isItemActive={isItemActive}
            onClick={getNavigateTo({ path: 'ADMIN' })}
          />
        }
        disabled={isOpen}
      >
        <div css={[styles.builderWrapper, !showMore && styles.bottomBorder]}>
          <ItemMenu
            recordTitle="Builder"
            recordImage="grid_view"
            optionList={[]}
            onToggle={onToggle}
            onClick={getNavigateTo({ path: 'ADMIN' })}
            isActive={isBuilderActive}
            isShowMore={showMore}
            isOpen={isOpen}
            containChildren={true}
            id={BUILDER_ID}
          />
        </div>
      </ItemMenuPopover>

      <div css={[styles.menuWrapper, styles.bottomBorder, showMore && isOpen ? styles.show : styles.hide]}>
        <SubItemMenu items={builderRoutes} isItemActive={isItemActive} />
      </div>
    </>
  );
};
