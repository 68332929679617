import { COLOR, DROPDOWN_BASE_STYLES, GAP, Z_INDEX } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  container: css`
    ${DROPDOWN_BASE_STYLES};
    padding: ${GAP.M} 0;
    width: 300px;
    height: max-content;
    max-height: 600px;
    gap: ${GAP.M};
    background-color: ${COLOR.BASE_BACKGROUND_ALT_1};
    overflow: auto;
    position: relative;
  `,
  main: css`
    z-index: ${Z_INDEX.POPOVER};
  `,
  transparent: css`
    background-color: transparent;
  `
};
