import { useContext, useState } from 'react';

import { isEmptyObject, ViewModel } from '@cyferd/client-engine';

import { isSavedRecord } from '@constants';
import type { EditorContextValue } from '../../../shared/EditorHome';
import { EditorContext } from '../../../shared/EditorHome';
import { EmptyState } from '@components/elements/EmptyState';
import { styles } from './styles';
import { CyView } from '@components/smart/CyView';
import { CyForm } from '@components/smart/CyForm';
import { CyLayout } from '@components/smart/CyLayout';

export const Preview = () => {
  const { item } = useContext<EditorContextValue<ViewModel.View>>(EditorContext);
  const [input, setInput] = useState({});

  if (!item) return <EmptyState />;
  const isNew = !isSavedRecord(item);

  return (
    <div className={styles.container}>
      {isNew ? (
        'Save to preview'
      ) : (
        <CyLayout type={ViewModel.LayoutType.FULL} framed={true}>
          {!isEmptyObject(item.model?.schema?.properties) && (
            <CyLayout title="Input" isCollapsible={true} startsCollapsed={true}>
              <CyForm model={item.model} onChange={({ record }) => setInput(record) as any} />
            </CyLayout>
          )}
          <CyView viewId={item.id} input={input} fitToPage={true} />
        </CyLayout>
      )}
    </div>
  );
};
