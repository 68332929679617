import { useCallback, useContext, useEffect } from 'react';
import { Subject, filter, finalize, take, tap } from 'rxjs';

import { GlobalContext } from '../../state-mgmt/GlobalState';
import { setModalInteraction } from '../../state-mgmt/ui/slice';
import { ConfirmationModal } from '@components/elements/ConfirmationModal';
import { PreventClickPropagation } from '@components/elements/PreventClickPropagation';

export const ModalInteraction = () => {
  const { state, deps, dispatch } = useContext(GlobalContext);

  const onChange = useCallback(
    (hasConfirmed: boolean) => {
      state.ui.modalInteraction?.change$?.next(hasConfirmed);
      state.ui.modalInteraction?.change$?.complete();
    },
    [state.ui.modalInteraction?.change$]
  );

  /** setting the dep here cause it needs access to dispatch */
  useEffect(() => {
    deps.modalInteraction.onAlert = props => dispatch(setModalInteraction(props));
    deps.modalInteraction.onConfirm = (props, ifCb) => {
      const change$ = new Subject<boolean>();
      dispatch(setModalInteraction({ ...props, change$ }));
      change$
        .pipe(
          filter(Boolean),
          take(1),
          tap(ifCb),
          finalize(() => dispatch(setModalInteraction(null)))
        )
        .subscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!state.ui.modalInteraction) return null;

  return (
    <PreventClickPropagation>
      <ConfirmationModal open={true} onChange={onChange} {...state.ui.modalInteraction} />
    </PreventClickPropagation>
  );
};
