import type { MutableRefObject } from 'react';
import { tsvToArray } from '@utils';
import type { RowColOrNull } from '../useNavigableGrid';
import { parsePastedValue } from './parsePastedValue';

type OnPasteParams = {
  containerRef: MutableRefObject<HTMLTableSectionElement>;
  selected: RowColOrNull;
  cursor: RowColOrNull;
  properties: Record<string, any>;
  change: (rows: Record<string, any>) => void;
};

const getRowId = (rowIndex: number, containerRef: MutableRefObject<HTMLTableSectionElement>) =>
  containerRef.current.querySelector(`[data-rowindex="${rowIndex}"]`)?.getAttribute('data-rowid');

const getCellDefinitionId = (row, col, containerRef) =>
  containerRef.current.querySelector(`[data-rowindex="${row}"][data-colindex="${col}"]`)?.getAttribute('data-definitionid');

const generateFilledPasteMap = (
  rowIndexes: number[],
  colIndexes: number[],
  value: string,
  containerRef: MutableRefObject<HTMLTableSectionElement>,
  properties: Record<string, any>
) => {
  return rowIndexes.reduce((acc, rowIndex) => {
    const rowId = getRowId(rowIndex, containerRef);
    return {
      ...acc,
      [rowId]: colIndexes.reduce((acc, colIndex) => {
        const definitionId = getCellDefinitionId(rowIndex, colIndex, containerRef);
        return {
          ...acc,
          [definitionId]: parsePastedValue(value, properties?.[rowId]?.[definitionId])
        };
      }, {})
    };
  }, {});
};

// TODO: This should also update the selection to be the latest modified cells
export const onPaste = (e: ClipboardEvent, { containerRef, selected, cursor: paramsCursor, properties, change }: OnPasteParams) => {
  e.preventDefault();

  const cursor = paramsCursor || selected;
  if (!selected || !cursor) return;

  const rows = tsvToArray(e.clipboardData?.getData('text/plain'));
  const isSingleValue = rows.length === 1 && rows[0].length === 1;

  const startRow = Math.min(selected.row, cursor.row);
  const startCol = Math.min(selected.col, cursor.col);

  if (isSingleValue) {
    const endRow = Math.max(selected.row, cursor.row);
    const endCol = Math.max(selected.col, cursor.col);

    // We get the cell indexes that we need to fill
    const rowIndexes = Array.from({ length: endRow - startRow + 1 }, (_, i) => startRow + i);
    const colIndexes = Array.from({ length: endCol - startCol + 1 }, (_, i) => startCol + i);

    const value = rows[0][0];
    const pasteMap = generateFilledPasteMap(rowIndexes, colIndexes, value, containerRef, properties);
    change(pasteMap);
    return;
  }

  const pasteMap = rows.reduce((acc, row, rowIndex) => {
    const targetRowIndex = startRow + rowIndex;
    const rowId = getRowId(targetRowIndex, containerRef);
    return {
      ...acc,
      [rowId]: row.reduce((acc, value, colIndex) => {
        const targetColIndex = startCol + colIndex;
        const definitionId = getCellDefinitionId(targetRowIndex, targetColIndex, containerRef);
        return {
          ...acc,
          [definitionId]: parsePastedValue(value, properties?.[rowId]?.[definitionId])
        };
      }, {})
    };
  }, {});

  change(pasteMap);
};
