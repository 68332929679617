import type { GeneralModel } from '@cyferd/client-engine';

type CoordinateValue = number | string;

export type Point =
  | { latitude: CoordinateValue; longitude: CoordinateValue }
  | { lat: CoordinateValue; lng: CoordinateValue } // Google Maps standard
  | [CoordinateValue, CoordinateValue]; // ISO

export type Polygon = Point[];
export type MultiPolygon = Polygon[];
export type Position = Point | Polygon | MultiPolygon;

export type CoordsPoint = [number, number];
export type Coords = CoordsPoint | CoordsPoint[] | CoordsPoint[][];

export enum MarkerType {
  POLYGON = 'polygon',
  CIRCLE = 'circle',
  POINT = 'point',
  INVALID = 'invalid'
}

export enum MapCenterType {
  AUTO = 'auto',
  FIXED = 'fixed'
}

export type MapMarkerProps = {
  type: MarkerType;
  title: string;
  description: string;
  color: string;
  rawColor: GeneralModel.JSONSchemaFormat.COLOR;
  icon: GeneralModel.JSONSchemaFormat.ICON;
  radius: number;
  coords: Coords;
  onClick: ({ metaKey, coordinates }) => void;
};
