import { COLOR, FONT_WEIGHT, FOREGROUND_COLOR, GAP, HC_COLOR, HC_SECONDARY_COLOR, RADIUS } from '@constants';
import { css } from '@emotion/react';

export const getStyles = (color: string) => ({
  container: css`
    width: 100%;
    padding: 0 ${GAP.XS};
  `,
  optionPrevent: css`
    display: block;
    width: 100%;
  `,
  optionContent: css`
    cursor: pointer;
    border-radius: ${RADIUS.S};
    gap: ${GAP.XS};
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    :hover {
      [data-selector='actionContainer'] {
        display: block;
      }
    }
  `,
  recordTitle: css`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  `,
  optionContentOpen: css`
    padding: 0 ${GAP.M};
    justify-content: flex-start;
  `,
  optionContentIdle: css`
    color: ${COLOR.NEUTRAL_1};
    fill: none;
    :hover {
      fill: ${FOREGROUND_COLOR[color]};
      background-color: ${COLOR.NEUTRAL_4};
      [data-selector='wrapperIcon'] {
        background-color: ${COLOR[color] || COLOR.NEUTRAL_5};
      }
    }
    :focus {
      fill: ${FOREGROUND_COLOR[color]};
      background-color: ${HC_SECONDARY_COLOR[color]};
      font-weight: ${FONT_WEIGHT.BOLD};
      color: ${HC_COLOR[color]};
    }
    :focus:hover {
      fill: ${FOREGROUND_COLOR[color]};
      background-color: ${HC_SECONDARY_COLOR[color]};
      font-weight: ${FONT_WEIGHT.BOLD};
      color: ${HC_COLOR[color]};
    }
  `,
  optionContentActive: css`
    background-color: ${HC_SECONDARY_COLOR.BRAND_1};
    font-weight: ${FONT_WEIGHT.BOLD};
    color: ${HC_COLOR.BRAND_1};
    &:focus {
      background-color: ${HC_SECONDARY_COLOR.BRAND_1};
      font-weight: ${FONT_WEIGHT.BOLD};
      color: ${HC_COLOR.BRAND_1};
    }
  `,
  actionContainer: css`
    margin-left: auto;
  `,
  wrapperIconOpen: css`
    border-radius: 4px;
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  wrapperIcon: css`
    width: 40px;
    height: 36px;
    border-radius: 4px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  `
});
