import { IconImage } from '@components/elements/Icon/renderIcon';
import { COLOR, FONT_SIZE, FONT_WEIGHT, GAP, HC_COLOR, HC_SECONDARY_COLOR } from '@constants';
import { SubItemMenu } from '../../../components/SubItemMenu';
import { css } from '@emotion/react';
import type { IconKeys } from '@components/elements/Icon';
import { memo } from 'react';

interface SideContentProps {
  items: any[];
  title: string;
  color?: string;
  icon: IconKeys | string;
  onClick: (payload?) => void;
  isActive: boolean;
  isItemActive: (id: string) => boolean;
}

const SideContentComponent = ({ items, title, color, icon, onClick, isActive, isItemActive }: SideContentProps) => {
  return (
    <>
      <div css={[styles.sideContent, isActive && styles.active]} onClick={onClick}>
        <IconImage
          icon={icon || 'apps'}
          title=""
          iconProps={{ size: FONT_SIZE.L, fill: isActive ? COLOR[color || 'BRAND_1'] : COLOR.NEUTRAL_1 }}
          imageProps={{ size: FONT_SIZE.L }}
        />
        <p css={styles.title}>{title}</p>
      </div>
      <SubItemMenu isItemActive={isItemActive} items={items} />
    </>
  );
};

const styles = {
  sideContent: css`
    display: flex;
    align-items: center;
    padding: 12px;
    padding-left: 20px;
    gap: ${GAP.S};
    cursor: pointer;
    :hover {
      background-color: ${COLOR.NEUTRAL_4};
    }
  `,
  active: css`
    background-color: ${HC_SECONDARY_COLOR.BRAND_1};
    font-weight: ${FONT_WEIGHT.BOLD};
    color: ${HC_COLOR.BRAND_1};
  `,
  title: css`
    text-align: start;
  `
};

export const SideContent = memo(SideContentComponent);
