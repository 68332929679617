import { useMemo, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router';

import { ApiModel, useTranslate } from '@cyferd/client-engine';

import { BUILDER_DESCRIPTION, SECTION_NAME, TRANS, isSavedRecord, BUILDER_ICON, BUILDER_ROUTE, QUERY_PARAM } from '@constants';
import { useQueryParamState } from '@utils/useQueryParamState';
import { EditorHome, useEditorHomeEffect, useEditorHomeOnRemove, useEditorHomeOnSave } from '../shared/EditorHome';
import { GeneralInfo } from './components/GeneralInfo';
import { GeneralList } from './components/GeneralList';

export const InvitationEditor = () => {
  const { translate } = useTranslate();
  const { pathname } = useLocation();
  const [queryParamState] = useQueryParamState();
  const itemId = queryParamState[QUERY_PARAM.RECORD_ID];
  const [original, setOriginal] = useState<ApiModel.ApiRecord>();
  const [isLoading, setLoading] = useState<boolean>(true);
  const isNew = !isSavedRecord(original);

  const routeList = useMemo(
    () => [
      { route: BUILDER_ROUTE.INVITATION_EDITOR.CHILDREN.LIST, label: null },
      { route: BUILDER_ROUTE.INVITATION_EDITOR.CHILDREN.GENERAL, label: 'Invitations' }
    ],
    []
  );

  const onSave = useEditorHomeOnSave(ApiModel.ApiEntity.INVITATION);
  const onRemove = useEditorHomeOnRemove(ApiModel.ApiEntity.INVITATION);

  useEditorHomeEffect({ itemId, collectionId: ApiModel.ApiEntity.INVITATION, setOriginal, setLoading });

  return (
    <EditorHome
      isLoading={isLoading}
      editorTitle={SECTION_NAME.INVITATIONS}
      title={translate(TRANS.client.nav.builder.newNames.invitations, { email: original?.email })}
      color={original?.recordColor}
      item={original}
      subtitle={BUILDER_DESCRIPTION.INVITATIONS}
      icon={BUILDER_ICON.INVITATIONS}
      /** header */
      routeList={routeList}
      rootPath={BUILDER_ROUTE.INVITATION_EDITOR.ROOT}
      isCurrentDraft={isNew}
      hideSubHeader={[
        '/',
        BUILDER_ROUTE.INVITATION_EDITOR.ROOT,
        `${BUILDER_ROUTE.INVITATION_EDITOR.ROOT}${BUILDER_ROUTE.INVITATION_EDITOR.CHILDREN.LIST}`
      ].includes(pathname)}
      onSave={onSave}
      onRemove={onRemove}
      onSetOriginalValue={setOriginal}
    >
      <Routes>
        <Route path={BUILDER_ROUTE.INVITATION_EDITOR.CHILDREN.LIST} element={<GeneralList />} />
        <Route path={BUILDER_ROUTE.INVITATION_EDITOR.CHILDREN.GENERAL} element={<GeneralInfo />} />
        <Route path="*" element={<GeneralList />} />
      </Routes>
    </EditorHome>
  );
};
