import { useCallback, useContext } from 'react';

import { ClientEngineContext, ErrorBoundary, GeneralModel, ViewModel } from '@cyferd/client-engine';

import { Search } from '@components/elements/Search';
import { UIContext } from '@components/providers/UIprovider';
import { GlobalToolbar } from './components/GlobalToolbar';
import { Print } from './components/Print';
import { Shortlink } from './components/Shortlink';
import { compactStyles } from './styles';
import { ViewUsageInApps } from './components/ViewUsageInApps';
import { CTA, CTAType } from '@components/elements/CTA';
import { IconImage } from '@components/elements/Icon/renderIcon';
import { styleHelpers } from '@utils/styleHelpers';
import { GAP } from '@constants';
import { useCyActions } from '@utils';
import { OnlyLogoV2 } from '../../../../../assets/OnlyLogo';
import { useNavigationMenuStore } from '../../store/NavigationMenuStore';

export const Header = ({ searchMenu, hiddenSearch, hiddenShortlink, hiddenFavorites, hiddenNotifications, hiddenPreferences, hiddenPrint, bannerHeight }) => {
  const { useUserSelector } = useContext(ClientEngineContext);
  const user = useUserSelector();

  const { size } = useContext(UIContext);

  const toggleSidebarVisibility = useNavigationMenuStore(state => state.toggleSidebarVisibility);

  const { onDispatchNavigateTo } = useCyActions();

  const tenantIcon = user?.tenant?.icon;

  const onLogoClick = useCallback(() => onDispatchNavigateTo({ path: '/' }), [onDispatchNavigateTo]);

  return (
    <header css={compactStyles.header} style={{ position: 'relative' }} data-testid="global-header" data-no-print={true}>
      <div css={compactStyles.container}>
        <div css={[compactStyles.section, styleHelpers.flexStart(GAP.L)]}>
          <CTA icon={{ name: 'menu', size: '24px' }} type={CTAType.SEEMLESS} size={ViewModel.CTASize.LARGE} onClick={toggleSidebarVisibility} />

          <CTA type={CTAType.SEEMLESS} onClick={onLogoClick} hideLoading={true}>
            {tenantIcon ? (
              <IconImage title="" icon={tenantIcon} iconProps={{ size: '28px' }} imageProps={{ css: compactStyles.imageContent }} />
            ) : (
              <CyferdLogo />
            )}
          </CTA>

          <div
            css={[
              size === GeneralModel.AppSize.M && compactStyles.viewUsageInAppsMediumScreen,
              size === GeneralModel.AppSize.L && compactStyles.viewUsageInAppsLargeScreen
            ]}
          >
            <ViewUsageInApps />
          </div>
        </div>

        <div css={[compactStyles.section, compactStyles.searchSection]}>
          <ErrorBoundary>
            {!hiddenSearch && <Search {...searchMenu} compact={size === GeneralModel.AppSize.S} expandCtaProps={{ color: 'HC_1' }} />}
          </ErrorBoundary>
          {size === GeneralModel.AppSize.S && <div css={compactStyles.division} />}
        </div>

        <div css={[compactStyles.section, compactStyles.tools, size === GeneralModel.AppSize.S && compactStyles.toolsSectionSmallScreen]}>
          {size === GeneralModel.AppSize.L && (
            <>
              <ErrorBoundary>{!hiddenShortlink && <Shortlink />}</ErrorBoundary>
              <ErrorBoundary>{!hiddenPrint && <Print />}</ErrorBoundary>
              <div css={compactStyles.division} />
            </>
          )}
          <GlobalToolbar
            verticalOffset={bannerHeight}
            hiddenFavorites={hiddenFavorites}
            hiddenNotifications={hiddenNotifications}
            hiddenPreferences={hiddenPreferences}
            hiddenShortlink={hiddenShortlink}
            hiddenPrint={hiddenPrint}
          />
        </div>
      </div>
    </header>
  );
};

const CyferdLogo = () => {
  return (
    <div css={styleHelpers.flexCenter}>
      <OnlyLogoV2 width={28} height={28} />
    </div>
  );
};
