// istanbul ignore file
import type { ViewModel } from '@cyferd/client-engine';
import L, { latLngBounds } from 'leaflet';
import { useContext, useEffect, useMemo, useRef } from 'react';
import { MapContainer, useMap as useLeafletMap } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import * as protomapsL from 'protomaps-leaflet';
import { BBContainer } from '@components/elements/BBContainer';
import { HasFlagRenderer } from '@components/elements/HasFlagRenderer';
import { ModalContainerContext } from '@components/elements/Modal/ModalContainerContext';
import { UIContext } from '@components/providers/UIprovider';
import { FLAGS, THEME } from '@constants';
import { useGetElementSize } from '@utils';
import { CyReusableActionEffect } from '../CyEffect';
import { CyWrapperContext } from '../CyWrapper';
import { useDataSource } from '../CyTable/hooks';
import { CyTableHeader } from '../CyTable/components/CyTableHeader';
import { useOnClick } from '../CyTable/components/ReadOnlyTable/useOnClick';
import { MapMarker } from './components/MapMarker';
import { useMap } from './hooks/useMap';

// TODO: replace with self-hosted tile server
const PROTOMAPS_URL = `https://api.protomaps.com/tiles/v3/{z}/{x}/{y}.mvt?key=e1cb0a6db95e223f`;
const mapThemes = {
  [THEME.DARK]: 'dark',
  [THEME.LIGHT]: 'light'
};

const RefreshBounds = props => {
  const map = useLeafletMap();

  useEffect(() => {
    if (props.bounds) map.fitBounds(props.bounds);
  }, [map, props.bounds]);

  useEffect(() => {
    map.attributionControl.setPrefix('<a href="https://leafletjs.com/">Leaflet</a>');
  }, []);

  return <></>;
};

const CyMapComponent = (
  {
    id,
    componentName,
    fitToPage,
    effectChildren,
    value: currentValue,
    // children,
    header = {},
    data = {},
    // editMode = {},
    // options = {},
    mapOptions = {},
    markerOptions = {},
    // TODO: recordActions for later, v1 only onClick
    rowActions = {}
    // appearance = {}
  }: any /* : CyMapProps */
) => {
  const refreshId = `refresh-${id}`;
  const { height, width, ref } = useGetElementSize();
  const mapRef = useRef();
  const { useOnRefresh } = useContext(CyWrapperContext);
  const { runtimeTheme } = useContext(UIContext);

  const { value, canLoad, isLoading, fetch, updateCursor } = useDataSource({
    pointer: componentName,
    data,
    currentValue
  });

  const { onClick } = useOnClick({ rowActions, isLoading, refreshId });

  const modalContainerContext = useContext(ModalContainerContext);
  const isModal = modalContainerContext?.instance;

  useOnRefresh({ id, componentName }, fetch);

  const { markers, center, zoom, bounds } = useMap({ value, mapOptions, markerOptions, onClick });

  const mapProps = {
    scrollWheelZoom: mapOptions.scrollWheelZoom,
    minZoom: mapOptions.minZoom,
    maxZoom: mapOptions.maxZoom,
    zoom,
    center,
    bounds,
    // these props make the map behave nicely when scrolling out of map limits
    worldCopyJump: true,
    maxBounds: latLngBounds([
      [-90, -300],
      [90, 300]
    ]),
    maxBoundsViscosity: 0.5,
    ref: e => (mapRef.current = e)
  };

  const createClusterIcon = cluster =>
    L.divIcon({
      html: `<div><span>${cluster.getChildCount()}</span></div>`,
      className: 'custom-marker-cluster',
      iconSize: L.point(40, 40, true)
    });

  useEffect(() => {
    if (!mapRef.current) return;

    const theme = mapThemes[runtimeTheme];

    const layer = protomapsL.leafletLayer({ url: PROTOMAPS_URL, theme, lang: 'en' });
    layer.addTo(mapRef.current);
  }, [mapRef.current, runtimeTheme]);

  const containerHeight = useMemo(() => {
    if (fitToPage) return '90%';
    if (!width) return '300px';

    return `${width * 0.75}px`;
  }, [width, fitToPage]);

  // TODO: route: https://router.project-osrm.org/route/v1/driving/2.15899,41.38879;-3.703790,40.416775?overview=simplified&steps=true
  return (
    <BBContainer framed={!isModal} fitToPage={fitToPage}>
      <div data-testid="effects">
        {effectChildren}
        <CyReusableActionEffect componentName={refreshId} id={refreshId} onPlay={fetch} />
      </div>
      <CyTableHeader
        refreshId={refreshId}
        value={value}
        query={value?.query}
        header={header}
        data={{
          canLoad,
          isLoading,
          updateCursor,
          fetch
        }}
      />
      <div ref={ref} style={{ height: containerHeight }}>
        {height && width ? (
          <MapContainer style={{ height: '100%', width: '100%' }} {...mapProps}>
            <MarkerClusterGroup iconCreateFunction={createClusterIcon} chunkedLoading={true} maxClusterRadius={mapOptions.maxClusterRadius ?? 100}>
              {markers.map((marker, index) => (
                <MapMarker key={index} {...marker} />
              ))}
            </MarkerClusterGroup>
            <RefreshBounds bounds={bounds} />
          </MapContainer>
        ) : null}
      </div>
    </BBContainer>
  );
};

const NotFound = () => 'CyMap is not available in your tenant';

export const CyMap = (props: ViewModel.ViewHeaderProps) => (
  <HasFlagRenderer NewComponent={CyMapComponent} LegacyComponent={NotFound} flag={FLAGS.CYFERD_LABS_CY_MAP} {...props} />
);

CyMap.displayName = 'CyMap';
