import { createSlice } from '@reduxjs/toolkit';
import { ApiModel } from '@cyferd/client-engine';
import type { UserState } from '@models/general';
import type { GeneralModel } from '@models/index';

export const getInitialState = (): UserState => null;

const userSlice = createSlice({
  name: 'user',
  initialState: getInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(ApiModel.TriggerActionType.DISPATCH_SET_DATA, (state: GeneralModel.UserState, action: GeneralModel.Action) => {
        if (action.payload?.section !== ApiModel.ApiSetDataSections.USER) return state;
        return { ...action.payload, organisation: action.payload?.organisation ?? 'Cyferd' };
      })
      .addCase(ApiModel.TriggerActionType.DISPATCH_SET_USER, (state: GeneralModel.UserState, action: GeneralModel.Action) => {
        return { ...action.payload, organisation: action.payload?.organisation ?? 'Cyferd' };
      });
  }
});

export type UserActions = typeof userSlice.actions;

export const userReducer = userSlice.reducer;
