import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { EMPTY } from 'rxjs';

import type { ViewModel, UserModel } from '@cyferd/client-engine';
import { createUUID, ApiModel } from '@cyferd/client-engine';

import { useTestingHelper } from '@utils';
import { BUILDER_DESCRIPTION, BUILDER_ICON, BUILDER_ROUTE, QUERY_PARAM, SECTION_NAME } from '@constants';
import type { getNavigateToArgs } from '@utils/getNavigateToTab';
import { getNavigateToTab } from '@utils/getNavigateToTab';
import { useOnNavigateToItem } from '@utils/useOnNavigateToItem';
import { useRequest } from '@utils/useRequest';
import { GlobalContext } from '../../../../state-mgmt/GlobalState';
import { BuilderCyList } from '../../../shared/BuilderCyList';
import type { EditorContextValue } from '../../../shared/EditorHome';
import { EditorContext } from '../../../shared/EditorHome';
import { TempHeader } from '../../../shared/TempHeader';
import { UserSecurityValues } from './components/UserSecurityValues';
import { styles } from './styles';

export const GeneralList = () => {
  const id = useMemo(createUUID, []);
  const [isSecurityOpen, setIsSecurityOpen] = useState<boolean>(false);
  const [openUser, setOpenUser] = useState<ApiModel.ApiRecord>(null);
  const { getTestIdProps } = useTestingHelper('general-list');
  const request = useRequest();
  const { setItem } = useContext<EditorContextValue<UserModel.User>>(EditorContext);
  const { search } = useLocation();
  const { deps } = useContext(GlobalContext);
  const navigate = useNavigate();

  const baseConfig: getNavigateToArgs = useMemo(
    () => ({
      id: null,
      path: null,
      root: BUILDER_ROUTE.USER_EDITOR.ROOT,
      search,
      key: QUERY_PARAM.RECORD_ID,
      push: navigate
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onEditSecurity = useCallback((item: ApiModel.ApiRecord) => {
    setIsSecurityOpen(true);
    setOpenUser(item);
    return EMPTY;
  }, []);

  const actionListChildren = useMemo(
    () =>
      [
        {
          icon: 'edit',
          label: 'Edit',
          important: true,
          onClick: (item, event) => getNavigateToTab({ ...baseConfig, id: item.id, path: BUILDER_ROUTE.USER_EDITOR.CHILDREN.GENERAL })(event)
        },
        { icon: 'security', label: 'Edit security', onClick: onEditSecurity }
      ] as ViewModel.CyListProps['actionListChildren'],
    [baseConfig, onEditSecurity]
  );

  const onClickItem = useOnNavigateToItem(baseConfig, BUILDER_ROUTE.USER_EDITOR.CHILDREN.GENERAL);

  const onSuccess = () => deps.refresh$.next(id);

  useEffect(() => {
    setItem(undefined);
  }, [setItem]);

  return (
    <>
      <TempHeader title={SECTION_NAME.USERS} icon={BUILDER_ICON.USERS} subtitle={BUILDER_DESCRIPTION.USERS} />
      <div {...getTestIdProps('container')} css={styles.container}>
        {isSecurityOpen && <UserSecurityValues open={isSecurityOpen} onClose={() => setIsSecurityOpen(false)} onSuccess={onSuccess} item={openUser} />}
        <BuilderCyList
          fitToPage={true}
          componentName={id}
          onClickItem={onClickItem}
          request={request}
          id={ApiModel.ApiEntity.USER}
          showDelete={true}
          key={ApiModel.ApiEntity.USER}
          collectionId={ApiModel.ApiEntity.USER}
          actionListChildren={actionListChildren}
        />
      </div>
    </>
  );
};
