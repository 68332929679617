import { memo, useCallback, useContext, useMemo, useState } from 'react';

import type { FlowModel } from '@cyferd/client-engine';
import { ClientEngineContext, ErrorBoundary, ViewModel, createUUID, getClassnames, removeKeyList, useTranslate } from '@cyferd/client-engine';
import { getPrettyActionTitle, useTestingHelper } from '@utils';

import { Adder } from '@components/elements/Adder';
import { EmptyState } from '@components/elements/EmptyState';
import { Fieldset } from '@components/elements/Fieldset';
import { Layout } from '@components/elements/Layout';
import { Modal } from '@components/elements/Modal';
import { TabList } from '@components/elements/TabList';
import { TRANS, defaultFormDetailGroupList } from '@constants';
import { getFlowInputList } from '@models/flow';
import type { EditorContextValue } from '../../../../shared/EditorHome';
import { EditorContext } from '../../../../shared/EditorHome';
import { SchemaForm } from '../../../../shared/SchemaForm';
import { getFlowComponentRecord } from '../../getFlowComponentRecord';
import { FlowChart } from './components/FlowChart';
import { FlowSidebar } from './components/FlowSidebar';
import { KeyValueMap } from './components/KeyValueMap';
import { ModelEditor } from './components/ModelEditor';
import {
  defaultStepImage,
  detailSchema,
  flowActionOptions,
  getActionDetailGroupList,
  getActionSchema,
  getOnErrorSchema,
  getOnResultSchema,
  outputSchema,
  tabConfigMap
} from './resources';
import { styles } from './styles';

export const FlowDefinition = memo(() => {
  const { useUserSelector } = useContext(ClientEngineContext);
  const user = useUserSelector();
  const { getTestIdProps } = useTestingHelper('steps-editor');
  const { item, setItem } = useContext<EditorContextValue<FlowModel.Flow>>(EditorContext);
  const [activeStepKey, setActiveStepKey] = useState<string>(null);
  const [activeTab, setActiveTab] = useState<string>(null);
  const [addModalVisible, setAddModalVisible] = useState<boolean>(false);
  const { translate } = useTranslate();

  const onToggleAddModal = useCallback(() => setAddModalVisible(p => !p), []);

  const initialStepId = useMemo(() => `initial-step-${createUUID()}`, []);

  const initialStep: FlowModel.FlowStep = useMemo(
    () => ({
      id: initialStepId,
      name: '',
      metadata: { image: tabConfigMap.start.image, color: tabConfigMap.start.color },
      action: '',
      input: null,
      onResult: (item?.onStart || []).filter(({ goTo }) => !!(item?.steps || {})[goTo])
    }),
    [item, initialStepId]
  );

  const chartSteps = useMemo(() => ({ [initialStep.id]: initialStep, ...item?.steps }), [item, initialStep]);

  const flowInputList = useMemo(() => getFlowInputList(item), [item]);

  const onTabChange = useCallback((event: string) => {
    setActiveStepKey(null);
    setActiveTab(p => (p === event ? null : event));
  }, []);

  const onActiveStepChange = useCallback(
    (stepKey: string, tabKey: string) => {
      const shouldShow = stepKey !== activeStepKey || activeTab !== tabKey;
      setActiveStepKey(shouldShow ? stepKey : null);
      setActiveTab(shouldShow ? tabKey : null);
      if (shouldShow) document.getElementById?.(stepKey)?.scrollIntoView?.();
    },
    [activeStepKey, activeTab]
  );

  const onAdd = useCallback(
    (action: FlowModel.FlowStep['action']) => {
      const stepId = createUUID();
      setItem({
        ...item,
        steps: {
          ...item.steps,
          [stepId]: { id: stepId, name: getPrettyActionTitle(action), action, debug: false, input: {}, onResult: [], onError: [] }
        }
      });
      onToggleAddModal();
      setTimeout(() => onActiveStepChange(stepId, tabConfigMap.step_detail.key));
    },
    [item, onActiveStepChange, onToggleAddModal, setItem]
  );

  const onStepChange = useCallback((key: string, step: FlowModel.FlowStep) => setItem({ ...item, steps: { ...item.steps, [key]: step } }), [item, setItem]);

  const onRemove = useCallback(
    (key: string) => {
      const filterFromResult = (r: FlowModel.FlowRouting) => r.goTo !== key;
      setItem({
        ...item,
        steps: Object.fromEntries(
          Object.entries(removeKeyList<FlowModel.Flow['steps']>(item.steps, [key])).map(([key, step]) => [
            key,
            {
              ...step,
              onResult: step?.onResult.filter(filterFromResult),
              onError: step?.onError.filter(filterFromResult)
            }
          ])
        ),
        onStart: item.onStart.filter(filterFromResult)
      });
    },
    [item, setItem]
  );

  const getOnKeyChange = (key: keyof FlowModel.Flow) => (event: FlowModel.Flow['state']) => setItem({ ...item, [key]: event });

  if (!item) return <EmptyState />;

  return (
    <>
      {!!addModalVisible && (
        <Modal
          type={ViewModel.ModalType.FULL_SCREEN}
          open={true}
          icon={defaultStepImage}
          title="Add step"
          description="Select an action to add a new step"
          onClose={onToggleAddModal}
        >
          <Adder options={flowActionOptions(user)} onSelect={onAdd} />
        </Modal>
      )}
      <Layout itemHeight={ViewModel.LayoutHeightPreset.REMAINING}>
        <div {...getTestIdProps('container')} className={styles.container}>
          {/* sidebar */}
          <div className={styles.sidebar}>
            <FlowSidebar
              flow={item}
              activeTab={activeTab}
              activeStepKey={activeStepKey}
              onTabChange={onTabChange}
              onActiveStepChange={onActiveStepChange}
              onAdd={onToggleAddModal}
              onRemove={onRemove}
            />
          </div>
          <div className={getClassnames(styles.mainContent, !tabConfigMap[activeTab] && styles.mainContentFullChart)}>
            {/* chart */}
            <div className={getClassnames(styles.content)}>
              <div className={styles.chartContainer} {...getTestIdProps('chart-container')}>
                <ErrorBoundary>
                  <FlowChart
                    steps={chartSteps}
                    initialStepId={initialStepId}
                    onSelect={/* istanbul ignore next */ k => onActiveStepChange(k, tabConfigMap.step_detail.key)}
                    onSelectStart={/* istanbul ignore next */ () => onTabChange(tabConfigMap.start.key)}
                    onChange={onStepChange}
                    onStartChange={/* istanbul ignore next */ event => getOnKeyChange('onStart')(event)}
                  />
                </ErrorBoundary>
              </div>
            </div>
            {/* forms */}
            {!!tabConfigMap[activeTab] && (
              <div className={getClassnames(styles.forms, /^step_/.test(activeTab) && styles.stepForms)}>
                {/^step_/.test(activeTab) && (
                  <TabList
                    activeTab={activeTab}
                    tabList={Object.values(tabConfigMap)
                      .filter(c => /^step_/.test(c.key))
                      .map(config => ({
                        title: config.key,
                        // calculatedTitle is a hack, only used here
                        displayName: config.calculatedTitle ? config.calculatedTitle(item.steps?.[activeStepKey], translate) : config.title
                      }))}
                    onChangeTab={event => event !== activeTab && (onActiveStepChange(activeStepKey, event) as any)}
                  />
                )}
                <div className={styles.formContent}>
                  <Fieldset
                    title={[item.steps?.[activeStepKey]?.name, translate(tabConfigMap[activeTab].title)].filter(Boolean).join(' - ')}
                    isDetailGroup={true}
                    image={item.steps?.[activeStepKey]?.metadata?.image || tabConfigMap[activeTab].image || defaultStepImage}
                    description={[item.steps?.[activeStepKey]?.description, tabConfigMap[activeTab].description].filter(Boolean).join(' ')}
                    optionList={[
                      {
                        important: true,
                        color: 'NEUTRAL_2',
                        tooltip: TRANS.client.buttons.close,
                        testid: 'close-btn',
                        size: ViewModel.CTASize.LARGE,
                        image: 'close',
                        onClick: () => onTabChange(activeTab)
                      }
                    ]}
                  >
                    <div className={styles.content}>
                      {(() => {
                        switch (activeTab) {
                          case tabConfigMap.state.key:
                            return (
                              <div data-testid={`${tabConfigMap.state.key}-editor`}>
                                <KeyValueMap value={item.state} onChange={getOnKeyChange('state')} inputList={flowInputList} />
                              </div>
                            );
                          case tabConfigMap.input.key:
                            return (
                              <div data-testid={`${tabConfigMap.input.key}-editor`}>
                                <ModelEditor value={item.model} onChange={getOnKeyChange('model')} />
                              </div>
                            );
                          case tabConfigMap.prepare.key:
                            return (
                              <div data-testid={`${tabConfigMap.prepare.key}-editor`}>
                                <KeyValueMap value={item.prepare} onChange={getOnKeyChange('prepare')} inputList={flowInputList} />
                              </div>
                            );
                          case tabConfigMap.output.key:
                            return (
                              <div data-testid={`${tabConfigMap.output.key}-editor`}>
                                <SchemaForm
                                  delayTime={0}
                                  addDefaults={false}
                                  detailGroupList={defaultFormDetailGroupList}
                                  schema={outputSchema}
                                  value={item.output}
                                  onChange={getOnKeyChange('output')}
                                  inputList={flowInputList}
                                />
                              </div>
                            );
                          case tabConfigMap.start.key:
                            return (
                              <div data-testid={`${tabConfigMap.start.key}-editor`}>
                                <SchemaForm
                                  delayTime={0}
                                  addDefaults={false}
                                  detailGroupList={defaultFormDetailGroupList}
                                  schema={{ type: 'object', properties: { onStart: getOnResultSchema(item).properties.onResult } }}
                                  value={item}
                                  onChange={event => getOnKeyChange('onStart')(event.onStart)}
                                  inputList={flowInputList}
                                />
                              </div>
                            );
                          case tabConfigMap.step_detail.key:
                            return (
                              <div data-testid={`${tabConfigMap.step_detail.key}-editor`}>
                                <SchemaForm
                                  id={activeStepKey}
                                  delayTime={0}
                                  addDefaults={false}
                                  detailGroupList={defaultFormDetailGroupList}
                                  schema={detailSchema}
                                  value={item.steps?.[activeStepKey]}
                                  onChange={event => onStepChange(activeStepKey, event)}
                                  inputList={flowInputList}
                                />
                              </div>
                            );
                          case tabConfigMap.step_action.key:
                            return (
                              <div data-testid={`${tabConfigMap.step_action.key}-editor`}>
                                <SchemaForm
                                  delayTime={0}
                                  addDefaults={false}
                                  detailGroupList={[...getActionDetailGroupList(item.steps?.[activeStepKey]?.action), ...defaultFormDetailGroupList]}
                                  schema={getActionSchema(item.steps?.[activeStepKey]?.action)}
                                  value={item.steps?.[activeStepKey]}
                                  onChange={event => onStepChange(activeStepKey, event)}
                                  inputList={flowInputList}
                                  getComponentRecord={getFlowComponentRecord}
                                />
                              </div>
                            );
                          case tabConfigMap.step_next.key:
                            return (
                              <div data-testid={`${tabConfigMap.step_next.key}-editor`}>
                                <SchemaForm
                                  delayTime={0}
                                  addDefaults={false}
                                  detailGroupList={defaultFormDetailGroupList}
                                  schema={getOnResultSchema(item)}
                                  value={item.steps?.[activeStepKey]}
                                  onChange={event => onStepChange(activeStepKey, event)}
                                  inputList={flowInputList}
                                />
                              </div>
                            );
                          case tabConfigMap.step_error.key:
                            return (
                              <div data-testid={`${tabConfigMap.step_error.key}-editor`}>
                                <SchemaForm
                                  delayTime={0}
                                  addDefaults={false}
                                  detailGroupList={defaultFormDetailGroupList}
                                  schema={getOnErrorSchema(item)}
                                  value={item.steps?.[activeStepKey]}
                                  onChange={event => onStepChange(activeStepKey, event)}
                                  inputList={flowInputList}
                                />
                              </div>
                            );
                        }
                      })()}
                    </div>
                  </Fieldset>
                </div>
              </div>
            )}
          </div>
        </div>
      </Layout>
    </>
  );
});
