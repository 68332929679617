import { ViewModel } from '@cyferd/client-engine';

export const builderNodeForms = {
  [ViewModel.DisplayName.CY_ACTION_EFFECT]: ViewModel.CyActionEffectBuilderModel,
  [ViewModel.DisplayName.CY_DATA_EFFECT]: ViewModel.CyDataEffectsBuilderModel,
  [ViewModel.DisplayName.CY_CHART]: ViewModel.CyChartBuilderModel,
  [ViewModel.DisplayName.CY_LOAD_EFFECT]: ViewModel.CyLoadEffectBuilderModel,
  [ViewModel.DisplayName.CY_MODAL]: ViewModel.CyModalBuilderModel,
  [ViewModel.DisplayName.CY_TABLE]: ViewModel.CyTableBuilderModel,
  [ViewModel.DisplayName.CY_MAP]: ViewModel.CyMapBuilderModel,
  [ViewModel.DisplayName.CY_UNLOAD_EFFECT]: ViewModel.CyUnloadEffectBuilderModel,
  [ViewModel.DisplayName.CY_INTERVAL_EFFECT]: ViewModel.CyIntervalEffectBuilderModel,
  [ViewModel.DisplayName.CY_REUSABLE_ACTION_EFFECT]: ViewModel.CyReusableActionEffectBuilderModel
};
