import { useFeatureFlag } from '@utils';

import { FlowDefinition as NewFlowDefinition } from './FlowDefinition';
import { FlowDefinition as LegacyFlowDefinition } from './Legacy';
import { FLAGS } from '@constants';

export const FlowDefinition = props => {
  const shouldUseNew = useFeatureFlag({ flag: FLAGS.EARLY_ACCESS });

  if (shouldUseNew) return <NewFlowDefinition {...props} />;
  return <LegacyFlowDefinition {...props} />;
};
