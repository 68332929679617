import { createSlice } from '@reduxjs/toolkit';
import { ApiModel } from '@cyferd/client-engine';
import type { GeneralModel } from '@models/index';

export const getInitialState = () => ({});

const contextSlice = createSlice({
  name: 'context',
  initialState: getInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(ApiModel.TriggerActionType.DISPATCH_MESSAGE, (state: GeneralModel.ContextState, action: GeneralModel.Action) => {
      return { ...state, ...action.payload };
    });
  }
});

export type ContextActions = typeof contextSlice.actions;
export type ContextState = ReturnType<typeof getInitialState>;

export const contextReducer = contextSlice.reducer;
