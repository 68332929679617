import type { INavigationItem } from '@components/platform/GlobalHeader/types';
import { memo, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { ItemMenuPopover } from '../../ItemMenuPopover';
import { SideContent } from '../../SideContent';
import { ItemMenu } from '../../ItemMenu';
import { styles as navigationStyles } from '../../../styles';
import { SubItemMenu } from '../../../../SubItemMenu';
import { useSearchStore } from '../../../../../../../store/SearchStore';
import { useNavigationMenuStore } from '../../../../../../../store/NavigationMenuStore';

interface AppRowProps extends INavigationItem {
  isActive?: boolean;
  isOpen?: boolean;
  onClick: (payload?) => void;
  onNavigateToView: (viewId: string, contextAppId: string) => void;
  onNavigateToCollection: (collectionId: string, contextAppId: string) => void;
  onFlowClick: (flowId: string, event: unknown) => void;
  _onCollectionCreate: (collectionId: string, event: unknown) => void;
}

const AppRowComponent = (props: AppRowProps) => {
  const {
    id,
    recordTitle,
    recordColor,
    recordImage,
    isActive,
    isOpen,
    onClick,
    collections,
    views,
    flows,
    onFlowClick,
    onNavigateToCollection,
    onNavigateToView,
    _onCollectionCreate
  } = props;

  const searchQuery = useSearchStore(state => state.searchQuery);

  const openItem = useNavigationMenuStore(state => state.openItem);
  const closeItem = useNavigationMenuStore(state => state.closeItem);
  const isSelected = useNavigationMenuStore(state => state.itemIds[id]);
  const onToggle = useCallback(() => (isSelected ? closeItem() : openItem(id)), [isSelected, id, closeItem, openItem]);

  const containChildren = collections?.length + views?.length > 0;

  const showMore = (isSelected || !!searchQuery) && isOpen;

  const { pathname, search } = useLocation();
  const activeId = new URLSearchParams(search).get('collectionId') || pathname.replace('/', '');

  const isAppActive = useCallback((itemId: string) => activeId === itemId, [activeId]);

  const optionList = useMemo(
    () =>
      flows.map(flow => ({
        label: flow.recordTitle,
        image: flow.recordImage,
        color: flow.recordColor,
        onClick: (event: unknown) => onFlowClick(flow.id, event)
      })),
    [flows, onFlowClick]
  );

  const displayItems = useMemo(() => {
    const filteredViews = views.filter(({ recordTitle }) => recordTitle.toLowerCase().includes(searchQuery.toLowerCase()));
    const filteredCollections = collections.filter(({ recordTitle }) => recordTitle.toLowerCase().includes(searchQuery.toLowerCase()));

    const displayViews = filteredViews.map(item => ({ title: item.recordTitle, id: item.id, onClick: onNavigateToView(item.id, id), icon: item.recordImage }));
    const displayCollections = filteredCollections.map(item => ({
      title: item.recordTitle,
      id: item.id,
      onClick: onNavigateToCollection(item.id, id),
      icon: item.recordImage,
      optionsList: []
      // commented till we figure out the thing with permissions
      // optionsList: [{ label: 'Create new', image: 'add', onClick: (event: unknown) => onCollectionCreate(item.id, event) }]
    }));

    return [...displayViews, ...displayCollections].sort((a, b) => a.title.localeCompare(b.title));
  }, [id, searchQuery, collections, views, onNavigateToCollection, onNavigateToView]);

  return (
    <>
      <ItemMenuPopover
        content={
          <SideContent
            title={recordTitle}
            icon={recordImage}
            color={recordColor}
            isActive={isActive}
            isItemActive={isAppActive}
            items={displayItems}
            onClick={onClick}
          />
        }
        disabled={isOpen}
      >
        <ItemMenu
          id={id}
          isActive={isActive}
          isOpen={isOpen}
          onToggle={onToggle}
          onClick={onClick}
          isShowMore={isSelected}
          containChildren={containChildren}
          recordTitle={recordTitle}
          recordImage={recordImage}
          optionList={optionList}
          recordColor={recordColor}
        />
      </ItemMenuPopover>
      <div css={showMore ? navigationStyles.show : navigationStyles.hide}>
        <SubItemMenu items={displayItems} isItemActive={isAppActive} isOpen={isOpen} />
      </div>
    </>
  );
};

export const AppRow = memo(AppRowComponent);
