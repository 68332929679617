import { create } from 'zustand';

interface SearchStore {
  searchQuery: string;
  setSearchQuery: (query: string) => void;

  builderHasResults: boolean;
  setBuilderHasResults: (hasResults: boolean) => void;

  appsHaveResults: boolean;
  setAppsHaveResults: (hasResults: boolean) => void;
}

export const useSearchStore = create<SearchStore>(set => ({
  searchQuery: '',
  setSearchQuery: query => set(state => (state.searchQuery !== query ? { ...state, searchQuery: query } : state)),

  builderHasResults: false,
  setBuilderHasResults: hasResults => set(state => (state.builderHasResults !== hasResults ? { ...state, builderHasResults: hasResults } : state)),

  appsHaveResults: false,
  setAppsHaveResults: hasResults => set(state => (state.appsHaveResults !== hasResults ? { ...state, appsHaveResults: hasResults } : state))
}));
