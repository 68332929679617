import { useCyActions } from '@utils';
import { useCallback } from 'react';
import { styles } from '../styles';
import { ItemMenuPopover } from './ItemMenuPopover';
import { ItemMenu } from './ItemMenu';
import { SubItemMenu } from '../../../components/SubItemMenu';
import type { NavigationMenuProps } from '@components/platform/GlobalHeader/types';
import { useNavigationMenuStore } from '@components/platform/MainLayout/store/NavigationMenuStore';
import { RECENT_VIEWS_ID } from '../constants';

interface IRecentViews {
  isOpen: boolean;
  recentViews: NavigationMenuProps['recentViews'];
}

export const RecentViews = ({ recentViews, isOpen }: IRecentViews) => {
  const { onDispatchNavigateTo } = useCyActions();
  const handleClick = useCallback((path: string, qs: unknown) => () => onDispatchNavigateTo({ path, qs }), [onDispatchNavigateTo]);

  const isSelected = useNavigationMenuStore(state => state.itemIds[RECENT_VIEWS_ID]);
  const closeItem = useNavigationMenuStore(state => state.closeItem);
  const openItem = useNavigationMenuStore(state => state.openItem);

  const onClick = useCallback(() => (isSelected ? closeItem() : openItem(RECENT_VIEWS_ID)), [isSelected, closeItem, openItem]);

  const showRecentViews = recentViews.length > 0;

  if (!showRecentViews) return null;

  return (
    <>
      <ItemMenuPopover disabled={isOpen} content={<RecentViewsList recentViews={recentViews} handleClick={handleClick} />}>
        <ItemMenu
          onClick={onClick}
          optionList={[]}
          containChildren={true}
          isActive={false}
          recordTitle="Recent views"
          isShowMore={isSelected}
          recordImage="history"
          isOpen={isOpen}
          onToggle={onClick}
          id={RECENT_VIEWS_ID}
        />
      </ItemMenuPopover>
      <div css={isOpen && isSelected ? styles.show : styles.hide}>
        <RecentViewsList recentViews={recentViews} handleClick={handleClick} />
      </div>
    </>
  );
};

const RecentViewsList = ({ recentViews, handleClick }) => {
  const recentViewsFormatted = recentViews.map(item => ({
    id: item.id,
    title: item.navigationInfo?.recordTitle || item.viewId,
    icon: item.navigationInfo?.recordImage,
    onClick: handleClick(item.viewId, item.input)
  }));

  return <SubItemMenu items={recentViewsFormatted} />;
};
