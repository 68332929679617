import { GeneralModel, TranslationModel, ViewModel } from '@cyferd/client-engine';

export const translation = {
  ...TranslationModel.es.translation,
  client: {
    schemaEditor: {
      groups: {
        titles: {
          basicInformation: 'Información básica',
          advancedConfig: 'General',
          associationSettings: 'Configuración de asociación',
          advancedFormatting: 'Apariencia',
          actions: 'Acciones',
          validation: 'Validación',
          advancedValidation: 'Validación avanzada',
          options: 'Opciones',
          recordAttrs: 'Atributos del registro',
          defaultFilters: 'Filtros por defecto',
          search: 'Búsqueda',
          defaultDisplay: 'Visualización por defecto'
        },
        descriptions: {
          hidden: 'Controla si el grupo se muestra',
          shouldCleanHidden: 'Cuando el grupo está oculto, al guardar o abandonar el formulario los valores se perderán',
          disabled: 'Controla si el grupo está deshabilitado'
        }
      },
      fields: {
        titles: {
          fixedValue: 'Contenido',
          option: 'Opción',
          required: 'Campo requerido',
          title: 'Nombre del campo',
          label: 'Espejo del nombre del campo',
          type: 'Tipo',
          description: 'Descripción corta',
          info: 'Descripción larga',
          detailPriority: { title: 'Dónde mostrar', important: 'Mostrar en todas partes', other: 'Mostrar únicamente en formulario' },
          minProperties: 'Propiedades minimas',
          color: 'Color',
          pattern: 'Expresión regular',
          calculation: 'Cálculo',
          subtype: 'Sub tipo',
          mask: 'Máscara',
          booleanLabelTrue: 'Texto para el estado "true"',
          booleanLabelFalse: 'Texto para el estado "false"',
          hidden: 'Escondido',
          allowFileInput: 'Permitir importar archivo',
          default: 'Valor inicial',
          format: 'Tipo de dato',
          maxLength: 'Longitud máxima',
          minLength: 'Longitud mínima',
          multipleOf: 'Múltiplos de',
          maximum: 'Máximo',
          minimum: 'Mínimo',
          optionList: { title: 'Opciones desplegables', cta: 'Añadir opción' },
          maxItems: 'Elementos máximos',
          minItems: 'Elementos mínimos',
          uniqueItems: 'Elementos unicos',
          unlimitedHeight: 'Altura completa',
          disabledType: { title: 'Tipo de deshabilitado', disabled: 'Normal', readonly: 'Solo lectura' },
          disabled: 'Deshabilitado',
          icon: 'Icono',
          shouldCleanHidden: 'Limpiar valores cuando este escondido',
          invalid: 'Mensaje personalizado de error',
          secondaryColor: 'Color secundario',
          fieldSize: { title: 'Ancho del campo', regular: 'Normal', full: 'Ancho completo' },
          startsCollapsed: 'Empieza colapsado',
          expanded: 'Expandido',
          ctaConfig: {
            ctaConfig: 'Configuración del botón',
            label: 'Añadir etiqueta del botón',
            image: 'Añadir imagen del botón',
            color: 'Añadir color del botón',
            type: 'Añadir tipo de botón'
          },
          iconImage: 'Icono',
          suggestionList: { title: 'Sugerencias', cta: 'Añadir sugerencia' },
          collectionId: 'Colección',
          recodId: 'Registro',
          fetchCriteria: 'Criterio de búsqueda',
          disableFreeText: 'Deshabilitar texto libre',
          additionalOptions: 'Opciones adicionales',
          excludeFields: 'Campos excluidos',
          model: 'Modelo',
          expandedImg: 'Minimizar imagenes en el formulario',
          sliderType: 'Tipo de selector',
          value: 'Valor',
          selectedColor: 'Color seleccionado',
          display: 'Visualización',
          framed: 'Enmarcado',
          unframed: 'No enmacado',
          allowedFileTypes: 'Tipos de archivos permitidos',
          assType: { ttile: 'Tipo de asociacion', list: 'Lista', kpi: 'KPI', table: 'Tabla', card: 'Cartas', grid: 'Cuadrícula', dropdown: 'Dropdown' },
          disableCreateNew: 'Esconder acción de crear nuevo',
          disableLink: 'Esconder acción de añadir',
          disableUnlink: 'Esconder acción de eliminar',
          limit: 'Límite',
          orderBy: 'Ordenar por',
          descending: 'Descendiente',
          defaultFilter: 'Filtro por defecto',
          select: 'Incluir campos',
          omit: 'Excluir campos',
          fixedFilter: 'Filtro fijo',
          preventLoad: 'Prevenir carga',
          recordTitle: 'Título del registro',
          recordDescription: 'Descripción del registro',
          recordImage: 'Imagen del registro',
          recordColor: 'Color del registro',
          defaultLimit: 'Limite por defecto',
          defaultKanbanField: 'Kanban por defecto (opcional)',
          defaultOrderBy: 'Orden de tabla por defecto',
          defaultDescending: 'Descendiente',
          address: {
            road: 'Calle',
            houseNumber: 'Número de vivienda',
            city: 'Ciudad',
            postcode: 'Código postal',
            state: 'Estado',
            country: 'País',
            lat: 'Latitud',
            lng: 'Longitud',
            county: 'Pais',
            category: 'Categoría',
            continent: 'Continente',
            countryCode: 'Código de pais',
            stateCode: 'Código de estado',
            suburb: 'Suburbio',
            stateDistrict: 'Distrito',
            required: 'Campos requeridos'
          },
          file: {
            id: 'ID',
            name: 'Nombre',
            encoding: 'Codificación',
            mimeType: 'Tipo',
            uploadedAt: 'Fecha de subida',
            uploadedBy: 'Subido por'
          }
        },
        descriptions: {
          disabledType: {
            disabled: 'Campo gris. Recomendado para campos que estan deshabilitados temporalmente.',
            readonly: 'Recomendado para campos cuyo contenido es relevante para el usuario, pero no puede ser editado directamente.'
          },
          address: {
            required: 'Selecciona los campos requeridos para esta dirección'
          },
          collectionLookup: {
            collectionId: 'ID de la colección usada para obtener los valores del selector',
            fetchCriteria: 'Introduce una query utilizada para obtener los valores del selector',
            disableFreeText: 'Deshabilita la introducción de texto manual',
            optionList: 'Lista de opciones fija para mostrar junto a los registros de la colección'
          },
          collectionField: {
            condition: 'Fórmula opcional para evaluar cada campo. El campo se ejecutará cuando devuelva VERDADERO'
          },
          collectionFilter: {
            collectionId: 'ID de la colección usada para obtener la entidad'
          },
          rating: { maximum: 'Selecciona un valor de 1 a 10', emptyColor: 'Color para cuando el icono está vacío' },
          object: {
            subtype: {
              framed: 'Data set por defecto que solo muestra campos dentro',
              unframed: 'Data set visible que puede mostrar título, usar color, etc.'
            }
          },
          file: {
            allowedFileTypes: 'Si no hay ninguno seleccionado se aceptarán todos los tipos de archivo'
          },
          association: {
            subtype: {
              dropdown: 'El Dropdown está limitado a 1 registro asociado como máximo. Si hay más de 1 registro asociado se mostrará como tipo Lista.'
            },
            defaultFilter: 'Aplicar un filtro por defecto al campo asociación que el usuario final podrá editar o eliminar',
            select: '<p>Selecciona campos para incluir.</p>',
            omit: '<p>Selecciona campos para omitir.</p>',
            fixedFilter: 'Aplicar un filtro fijo oculto al campo asociación'
          },
          description: 'Usado como ayuda en los formularios',
          info: 'Información extra que surge cuando un usuario interactua con el icono de ayuda',
          format: 'Define el tipo de campo que afecta a visualización de datos, validación y almacenamiento',
          pattern: 'Expresión regular personalizada para uso en validaciones',
          booleanLabelTrue: 'Si vacío, el icono de marca se mostrará por defecto',
          booleanLabelFalse: 'Si vacío, el icono de cruz se mostrará por defecto',
          color: 'Color para este campo',
          fieldSize: { description: 'Tamaño del campo en formularios', regular: 'Anchura por defecto', full: 'El campo usará toda la anchura disponible' },

          secondaryColor: 'Color secundario para el campo',
          invalid: 'Crear validaciones y errores personalizados. Cuando el campo es inválido la validación podría mostrar un error personalizado',
          detailPriority: {
            description: 'Controla la posición del campo',
            important: 'Importante',
            other: 'Otro'
          },
          mask: {
            description: 'Fórmula usada para mostrar el valor fuera de un formulario',
            mask_1: '123 | 123K | 123M',
            mask_2: '0.12 se convierte en 12%',
            mask_3: '0.75 se convierte en 3/4, 5.75 se convierte en 5 3/4',
            mask_4: '$ 123 | $ 123K | $ 123M',
            mask_5: '£ 123 | £ 123K | £ 123M',
            mask_6: '123 € | 123K € | 123M €'
          },
          hidden: 'Controla si el campo debe mostrarse',
          icon: 'Icono relacionado con el campo',
          iconImage: 'Icono o imagen relacionado con el campo',
          default: 'Valor por defecto',
          uniqueItems: 'Valida si los elementos deben ser únicos',
          unlimitedHeight: 'El campo pierde su limitacion de altura',
          optionList: {
            description: 'Lista fija de opciones para mostrar en el formulario',
            hidden: 'Controla si la opción se muestra en los formularios',
            color: 'Color de la opción'
          },
          suggestionList: 'Valores sugeridos',
          disabled: 'Establece si el campo está deshabilitado en formularios',
          shouldCleanHidden: 'Cuando el campo esté oculto al guardar o abandonar el form el valor se perderá',
          allowFileInput: 'Permitir la selección de archivos para usar su contenido en este campo'
        },
        info: {
          info: 'Este mensaje emergente es como se muestran las descripciones largas',
          association: {
            disableCreateNew: 'Eliminar la opción de crear nuevos registros desde este campo',
            disableLink: 'Eliminar la opción de enlazar registros a esta asociación',
            disableUnlink: 'Eliminar la opción de desenlazar registros de esta asociación',
            preventLoad:
              'Optimización del backend para reducir tiempos de carga. Los campos con esta opción activada son excluidos de los posibles cálculos efectuados'
          }
        }
      },
      infoBlocks: {
        general: {
          visibility: '<h5>Visibilidad</h5>',
          disabled: '<h5>Deshabilitado</h5>'
        },
        appearance: {
          size: '<h5>Tamaño</h5>'
        },
        association: {
          listOptions: '<h5>Opciones de lista</h5>',
          validation: '<h5>Validación</h5>',
          selectionFilters: '<h5>Filtros de selección</h5>',
          pickFields: '<h5>Campos</h5>',
          performance: '<h5>Performance</h5>'
        }
      }
    },
    triggerForm: {
      titles: {
        confirmLabel: 'Etiqueta del botón de confirmación',
        developerNotes: 'Notas de desarrollador',
        concurrent: 'Concurrente',
        skip: 'Omitir',
        delay: 'Demora',
        parameters: 'Parámetros',
        cancelBtnLabel: 'Etiqueta del botón de cancelación',
        userAlert: 'Mensaje de éxito',
        userError: 'Mensaje de error'
      },
      descriptions: {
        skip: 'No mostrar el mensaje de confirmación',
        trigger: 'Puede ser usado para crear una acción inline si ninguna ha sido seleccionada o sobreescribir una existente',
        delay: 'Tiempo de espera antes de ejecutar la acción',
        condition: 'La acción se ejecutará si evalua a cierto o no tiene ningún valor',
        concurrent: 'Ejecutar la acción sin esperar que la previa finalice',
        confirmationMessage: 'Define un mensaje de confirmación para el usuario antes de ejecutar la acción',
        userAlert: 'Feedback después de que finalice la acción',
        userError: 'Feedback después de que la acción genere un error'
      }
    },
    fields: {
      titles: {
        name: 'Nombre',
        publicName: 'Nombre público',
        description: 'Descripción',
        color: 'Color',
        icon: 'Icono',
        adminNotes: 'Notas de desarrollo',
        globalSearch: 'Incluir en resultados de búsqueda',
        hideApp: 'Esconder app',
        appHomeView: 'Vista de inicio de la app',
        id: 'ID',
        createdAt: 'Fecha de creación',
        updatedAt: 'Fecha de edición',
        createdBy: 'Creado por',
        updatedBy: 'Editado por',
        hideInApps: 'Esconder en app home',
        enabled: 'Habilitado',
        verboseLog: 'Log verboso',
        status: 'Estado',
        maxStepRuns: 'Máximos pasos ejecutados',
        schedules: 'Programaciones',
        schedule: 'Programación',
        nextRun: 'Próxima ejecución',
        payload: 'Payload',
        whenToRun: 'Cuándo ejecutar',
        type: 'Tipo',
        customPattern: 'Patrón personalizado',
        dayOfMonth: 'Día del mes',
        dayOWeek: 'Día de la semana',
        time: 'Hora',
        startDate: 'Fecha de inicio',
        endDate: 'Fecha de fin',
        timezone: 'Zona horaria',
        lastExecutedAt: 'Fecha de inicio de la ultima ejecución',
        lastFinishedAt: 'Fecha de fin de la ultima ejecución',
        nextScheduledAt: 'Próxima programación',
        consecutiveErrorCount: 'Errores consecutivos',
        tag: 'Etiqueta',
        input: 'Argumentos de entrada',
        fullName: 'Nombre completo',
        email: 'Email',
        roles: 'Roles',
        blockMobileAccess: 'Bloquear acceso a la aplicacion móvil',
        stepName: 'Nombre del paso',
        debug: 'Depurar',
        actionType: 'Tipo de acción',
        actionName: 'Nombre de la acción',
        stateChanges: 'Cambios de estado',
        nextStepItem: 'Próximo paso para ejecutar cuando la condición se cumpla',
        goTo: 'Nombre del próximo paso',
        condition: 'Condición',
        lineName: 'Nombre de la línea',
        stepErrorTypeList: 'Agrega una lista de pasos para un tipo de error',
        value: 'Valor',
        key: 'Clave',
        image: 'Imagen',
        hidden: 'Escondido',
        çgroupOrder: 'Orden del grupo',
        collapsible: 'Colapsable',
        startsCollapsed: 'Inicia colapsado',
        title: 'Título',
        label: 'Etiqueta',
        message: 'Mensaje',
        confirmationMessage: 'Mensaje de confirmación',
        above: 'Arriba',
        left: 'Izquierda',
        right: 'Derecha',
        customView: 'Vista personalizada',
        keepDraftVer: 'Guardar borrador',
        adminName: 'Nombre de administrador',
        buttonlabel: 'Etiqueta del botón',
        modalTitle: 'Título del modal'
      },
      descriptions: {
        optional: 'opcional'
      },
      info: {
        top: 'La vista personalizada se va a mostrar en la fila superior, por encima del contenido.',
        right: 'La vista personalizada se va a mostrar en la columna derecha, al lado del contenido.',
        left: 'La vista personalizada se va a mostrar en la columna izquierda, al lado del contenido.',
        color: `<p>Este color se va a mostrar:</p><ul><li>En la cabecera de la vista core de colección</li><li>Con la colección cuando este incluida en una lista</li></ul>`,
        image: `<p>Este color se va a mostrar:</p><ul><li>En la cabecera de la vista core de colección</li><li>Con la colección cuando este incluida en una lista</li></ul>`,
        adminName: 'Esta descripción solo es visible para los usuarios administradores.',
        adminNotes: 'Esta descripción solo es visible para los usuarios administradores.',
        buttonLabel: 'Etiqueta para el botón de acción.',
        modalTitle:
          '<p>El nombre público del flow será utilizado como título del modal por defecto.<br/><br/>Puedes personalizar el título del modal aquí si lo prefieres.</p>'
      }
    },
    groups: {
      titles: {
        basic: 'Información básica',
        naming: 'Nombres',
        date: 'Actividad',
        configuration: 'Configuración',
        advanced: 'Configuración avanzada',
        defaultDisplay: 'Configuración por defecto',
        coreViewsConfig: 'Agregar a la vista {{view}}',
        customizeCoreViews: 'Personaliza las vistas core',
        replaceCoreViews: 'Reemplaza las vistas core'
      },
      descriptions: {
        customizeCoreViews:
          'El comportamiento por defecto es que una coleccion se muestre en las vistas core. Se pueden customizar estas paginas instertando vistas personalizadas al rededor del contenido core.',
        replaceCoreViews: 'Reemplaza por completo las vistas core con personalizadas'
      }
    },
    emptyStates: {
      unlabeledPieItem: 'Sin nombre',
      noMobileApp: 'Aplicacion móvil no disponible en esta plataforma.',
      redirectingToMobileApp: 'Abriendo en la app...',
      cyList: `<h2 style="text-align: center;">No hay datos</h2><p style="text-align: center;"><span style="font-size: 14px; font-weight: 400; color: var(--NEUTRAL_2);">No hay información para mostrar</span></p>`,
      cyListWithFilters: `<h2 style="text-align: center;">No se encontraron datos</h2><p style="text-align: center;"><span style="font-size: 14px; font-weight: 400; color: var(--NEUTRAL_2);">No hay elementos que coincidan con tu criterio de búsqueda</span></p>`,
      collectionOverride: `<h2 style="text-align: center;">No hay datos</h2><p style="text-align: center;"><span style="font-size: 14px; font-weight: 400; color: var(--NEUTRAL_2);">Solo la configuracion de la primera app aplica.</span></p>`
    },
    buttons: {
      select: 'Selecciona',
      filters: 'Filtros',
      refresh: 'Recargar',
      quickFilters: 'Filtros rapidos',
      actions: 'Acciones',
      listType: 'Seleccione un tipo de lista',
      cancel: 'Cancelar',
      clearAll: 'Remover todo',
      clear: 'Remover',
      confirm: 'Confirmar',
      save: 'Guardar',
      apply: 'Aplicar',
      createNew: 'Crear nuevo',
      sortBy: 'Ordenar por',
      removeGroup: 'Eliminar groupo',
      duplicate: 'Duplicar',
      remove: 'Eliminar',
      addField: 'Agregar campo',
      addGroup: 'Agregar groupo',
      delete: 'Eliminar',
      copy: 'Copiar',
      deletePermanently: 'Eliminar definitivamente',
      saveAndExit: 'Guardar y salir',
      goBack: 'Volver',
      goHome: 'Volver a inicio',
      copyAddress: 'Copiar dirección',
      copyImage: 'Copiar URL de la imagen',
      copyReq: 'Copiar consulta',
      copyReqRaw: 'Copiar código de consulta',
      copyName: 'Copiar nombre',
      copyId: 'Copiar ID',
      copyGroupId: 'Copiar ID del grupo',
      open: 'Abrir',
      edit: 'Editar',
      close: 'Cerrar',
      create: 'Crear',
      openInApp: 'Abrir en la app',
      dontShowMeAgain: 'No mostrar de nuevo.',
      reset: 'Restablecer valor inicial',
      paste: 'Pegar'
    },
    pagination: {
      display: 'Mostrar',
      items: '{{i}} elementos',
      page: 'Página {{pageNumber}}',
      next: 'proxima página',
      prev: 'página anterior'
    },
    nav: {
      builder: {
        adminMenu: 'Menu de administrador',
        home: 'Inicio',
        builder: 'Builder',
        apps: 'Apps',
        data: 'Colecciones',
        flows: 'Flujos',
        views: 'Vistas',
        integrations: 'Integraciones',
        devices: 'Dispositivos',
        platform: 'Configuraciones de la plataforma',
        invitations: 'Invitaciones',
        users: 'Usuarios',
        playground: 'Herramientas',
        guide: 'Guia de Cyferd',
        pin: 'Pin menu',
        unpin: 'Unpin menu',
        tabs: {
          details: 'Detalles',
          linkedCollections: 'Colecciones conectadas',
          linkedFlows: 'Flujos conectados',
          linkedUsers: 'Usuarios conectados',
          linkedViews: 'Vistas conectadas',
          linkedIntegrations: 'Integraciones conectadas',
          linkedApps: 'Apps conectadas',
          coreViewConfig: 'Configuraciones de vista',
          fields: 'Campos',
          settings: 'Configuraciones',
          filters: 'Filtros',
          preview: 'Vista previa',
          definition: 'Definicion',
          logs: 'Registros',
          layout: 'Estructura',
          vars: 'Variables',
          mainView: 'Vista principal',
          modals: 'Modales',
          modalsCount: 'Modales ({{count}})',
          flows: {
            inputForm: 'Formulario de entrada',
            inputChanges: 'Cambios de entrada',
            savedState: 'Estado guardado',
            output: 'Salida',
            start: 'Comienzo',
            steps: 'Pasos',
            stepDetail: 'Detalles',
            stepAction: 'Accion',
            stepNext: 'Proximos pasos',
            stepError: 'Pasos de error'
          }
        },
        newNames: {
          apps: 'Nueva app',
          data: 'Nueva coleccion',
          flows: 'Nuevo flujo',
          views: 'Nueva vista',
          integrations: 'Nueva integracion',
          invitations: 'Invitacion para: {{email}}'
        }
      },
      share: 'Copia URL corta',
      print: 'Imprimir pagina',
      favs: 'Mis favoritos',
      apps: 'Mis apps',
      notifs: 'Notificaciones',
      dev: 'Herramientas de desarrollo',
      account: 'Mi cuenta',
      notifSettings: 'Configuración de notificaciones',
      notifAll: 'Ver todo',
      notifMark: 'Marcar todo como leído',
      accountMenu: {
        settings: 'Configuraciones',
        devices: 'Administrar dispositivos',
        lang: 'Lenguaje',
        theme: 'Tema',
        light: 'Claro',
        dark: 'Oscuro',
        os: 'Configuración del sistema',
        terms: 'Terminos & condiciones',
        priv: 'Política de privacidad',
        third: 'Software de terceros',
        logout: 'Cerrar sesión',
        notifPreferences: 'Preferencias de notificaciones'
      }
    },
    viewTabs: {
      basic: 'Básico',
      tabs: 'Solapas',
      input: 'Entradas',
      calendarList: 'Calendarios',
      advanced: 'Avanzado',
      headerActions: 'Botones',
      mainActions: 'Botones de acciones',
      itemActions: 'Botones de items',
      navigation: 'Barra de navegación',
      page: 'Fondo de la página',
      custom: 'Perzonalizadas',
      core: 'Sistema'
    },
    navSubtitle: {
      apps: 'Agrupa recursos y usuarios a ellas',
      data: 'Maneja tus estructuras de datos y sus asociaciones',
      flows: 'Define procesos personalizados para ejecutar manualmente, de manera programada o por otras acciones de Cyferd',
      views: 'Diseña vistas personalizadas para obtener incluso mas valor de tus datos',
      integrations: 'Maneja integraciones con terceros como bases de datos, REST API, MS Graph...',
      devices: 'Maneja el acceso a Cyferd de los usuarios a la aplicacion movil',
      invitations: 'Invita nuevos usuarios y visualiza sus permisos y roles',
      users: 'Asigna roles, permisos y maneja usuarios',
      platform: 'Maneja la configuración de tus Cyferd tenants'
    },
    placeholder: {
      search: 'Buscar',
      globalSearch: 'Busca cualquier cosa',
      calendarSearch: 'Busca eventos',
      assAdd: 'Busca en table',
      addressSearch: 'Busca una dirección',
      pickColor: 'Selecciona un color'
    },
    dataTypes: {
      modal: {
        title: 'Tipo de campo',
        description: 'Selecciona un tipo de dato'
      },
      categories: {
        text: 'Texto',
        number: 'Números',
        dates: 'Fecha y hora',
        options: 'Opciones',
        controls: 'Selectores & controles',
        contact: 'Información de contacto',
        share: 'Compartir archivos & enlaces',
        identifier: 'Identificadores',
        raw: 'Código',
        data: 'Datos',
        misc: 'Misc'
      },
      names: {
        [GeneralModel.JSONSchemaFormat.STRING_OPTION_LIST]: 'Opciones de texto',
        [GeneralModel.JSONSchemaFormat.ICON_IMAGE]: 'Icono o imagen',
        [GeneralModel.JSONSchemaFormat.NUMBER_OPTION_LIST]: 'Opciones numéricas',
        [GeneralModel.JSONSchemaFormat.INLINE_STRING_OPTION_LIST]: 'Opciones horizontales',
        [GeneralModel.JSONSchemaFormat.RANGE]: 'Selector numérico',
        [GeneralModel.JSONSchemaFormat.NUMERIC_RANGE]: 'Rango numérico',
        [GeneralModel.JSONSchemaFormat.CHECKBOX]: 'Checkbox',
        [GeneralModel.JSONSchemaFormat.SWITCH]: 'Switch',
        [GeneralModel.JSONSchemaFormat.OBJECT]: 'Set de datos',
        [GeneralModel.JSONSchemaFormat.ARRAY]: 'Lista',
        [GeneralModel.JSONSchemaFormat.MULTI_OPTION_LIST]: 'Lista de checkboxes',
        [GeneralModel.JSONSchemaFormat.MULTI_OPTION_LIST_ALT]: 'Lista de opciones',
        [GeneralModel.JSONSchemaFormat.DATE_TIME]: 'Fecha y hora',
        [GeneralModel.JSONSchemaFormat.DATE_TIME_U]: 'Fecha y hora',
        [GeneralModel.JSONSchemaFormat.PASSWORD]: 'Texto enmascarado',
        [GeneralModel.JSONSchemaFormat.COLLECTION_LOOKUP]: 'Buscador de clave foránea',
        [GeneralModel.JSONSchemaFormat.URL]: 'URL',
        [GeneralModel.JSONSchemaFormat.QR]: 'QR',
        [GeneralModel.JSONSchemaFormat.SQL]: 'SQL',
        [GeneralModel.JSONSchemaFormat.YAML]: 'YML',
        [GeneralModel.JSONSchemaFormat.XML]: 'XML',
        [GeneralModel.JSONSchemaFormat.GRAPHQL]: 'GraphQL',
        [GeneralModel.JSONSchemaFormat.JSON]: 'JSON',
        [GeneralModel.JSONSchemaFormat.ADDRESS]: 'Dirección',
        [GeneralModel.JSONSchemaFormat.TEXT]: 'Texto',
        [GeneralModel.JSONSchemaFormat.COLOR]: 'Color',
        [GeneralModel.JSONSchemaFormat.DATE]: 'Fecha',
        [GeneralModel.JSONSchemaFormat.EMAIL]: 'Email',
        [GeneralModel.JSONSchemaFormat.MONTH]: 'Mes',
        [GeneralModel.JSONSchemaFormat.WEEK]: 'Semana',
        [GeneralModel.JSONSchemaFormat.TIME]: 'Hora',
        [GeneralModel.JSONSchemaFormat.MULTILINE]: 'Texto multilínea',
        [GeneralModel.JSONSchemaFormat.ICON]: 'Icono',
        [GeneralModel.JSONSchemaFormat.ACTION_TYPE_OPTION_LIST]: 'Acciones',
        [GeneralModel.JSONSchemaFormat.CREDIT_CARD_NUMBER]: 'Tarjeta de crédito',
        [GeneralModel.JSONSchemaFormat.IBAN]: 'IBAN',
        [GeneralModel.JSONSchemaFormat.ISIN]: 'ISIN',
        [GeneralModel.JSONSchemaFormat.DUNS]: 'DUNS',
        [GeneralModel.JSONSchemaFormat.ISBN]: 'ISBN',
        [GeneralModel.JSONSchemaFormat.BARCODE]: 'Código de barras',
        [GeneralModel.JSONSchemaFormat.PHONE_NUMBER]: 'Número de teléfono',
        [GeneralModel.JSONSchemaFormat.COLLECTION_FIELD]: 'Campo de colección',
        [GeneralModel.JSONSchemaFormat.RICH_TEXT]: 'Texto complejo',
        [GeneralModel.JSONSchemaFormat.SEARCH]: 'Búsqueda',
        [GeneralModel.JSONSchemaFormat.NUMBER]: 'Número',
        [GeneralModel.JSONSchemaFormat.CURRENCY]: 'Moneda',
        [GeneralModel.JSONSchemaFormat.ADVANCED_CURRENCY]: 'Moneda avanzada',
        [GeneralModel.JSONSchemaFormat.RATING]: 'Rating',
        [GeneralModel.JSONSchemaFormat.EVALUATION]: 'Evaluación',
        [GeneralModel.JSONSchemaFormat.FILE]: 'Archivo',
        [GeneralModel.JSONSchemaFormat.COLLECTION_FILTER]: 'Filtro de colección',
        [GeneralModel.JSONSchemaFormat.COLLECTION_LITE]: 'Colección',
        [GeneralModel.JSONSchemaFormat.COLLECTION_RECORD]: 'Registro',
        [GeneralModel.JSONSchemaFormat.ASSOCIATION]: 'Asociación',
        [GeneralModel.JSONSchemaFormat.FILE_LIST]: 'Lista de archivos'
      }
    },
    ViewCompoNames: {
      [ViewModel.DisplayName.CY_TABLE]: 'Tabla editable',
      [ViewModel.DisplayName.CY_MAP]: 'Mapa',
      [ViewModel.DisplayName.CY_LIST]: 'CyList',
      [ViewModel.DisplayName.CY_FORM]: 'CyForm',
      [ViewModel.DisplayName.CY_MODAL]: 'CyModal',
      [ViewModel.DisplayName.CY_INFO]: 'CyInfo',
      [ViewModel.DisplayName.CY_CHART]: 'CyChart',
      [ViewModel.DisplayName.CY_KANBAN]: 'CyKanban board',
      [ViewModel.DisplayName.CY_LAYOUT]: 'CyLayout',
      [ViewModel.DisplayName.CY_BLANK]: 'Vacio',
      [ViewModel.DisplayName.CY_SEARCH]: 'CySearch',
      [ViewModel.DisplayName.CY_ACTION]: 'CyButtons',
      [ViewModel.DisplayName.CY_TEXT]: 'CyText',
      [ViewModel.DisplayName.CY_IMAGE]: 'CyImage',
      [ViewModel.DisplayName.CY_CALENDAR]: 'CyCalendar',
      [ViewModel.DisplayName.CY_ACTIVITY_LOG]: 'Registro de actividad',
      [ViewModel.DisplayName.CY_VIEW]: 'CyView',
      [ViewModel.DisplayName.CY_VIEW_CONTENT]: 'Contenido de la vista padre',
      [ViewModel.DisplayName.CY_DATA_EFFECT]: 'Cambio de datos o url',
      [ViewModel.DisplayName.CY_ACTION_EFFECT]: 'Evento de acción entrante',
      [ViewModel.DisplayName.CY_LOAD_EFFECT]: 'Carga',
      [ViewModel.DisplayName.CY_UNLOAD_EFFECT]: 'Descarga',
      [ViewModel.DisplayName.CY_INTERVAL_EFFECT]: 'Temporizador',
      [ViewModel.DisplayName.CY_REUSABLE_ACTION_EFFECT]: 'Acción reutlizable',
      [ViewModel.DisplayName.VIEW_HEADER]: 'Cabecera',
      [ViewModel.DisplayName.GLOBAL_HEADER]: 'Configuración de página',
      [ViewModel.DisplayName.NOT_FOUND]: 'No encontrado'
    },
    viewCompoCategories: {
      LAYOUT: 'Disposiciones',
      INFORMATION: 'Interacciones',
      DATA: 'Datos',
      OTHER: 'Otros',
      EFFECT: 'Acciones basadas en eventos',
      layoutBlocks: 'Bloques'
    },
    inputCompo: {
      [GeneralModel.JSONSchemaFormat.COLOR]: {
        recent: 'Recientes',
        palette: 'Paleta'
      }
    },
    buldingBlocks: {
      [ViewModel.DisplayName.CY_KANBAN]: {
        paginationInfoMessage: 'En este tablero de están mostrando un máximo de {{limit}} cartas. Puede haber más cartas no mostradas por la paginación.'
      }
    },
    SubHeader: {
      modalTitle: 'Eliminar elemento?',
      modalDescription: 'Estás tratando de eliminar este elemento. Estás seguro?'
    },
    ComponentAdder: {
      [ViewModel.DisplayName.CY_VIEW]: 'Carga una vista personalizada existente',
      [ViewModel.DisplayName.CY_VIEW_CONTENT]: 'Cuando está colocado dentro de un componente Vista, este componente mostrará contenido de la vista padre',
      [ViewModel.DisplayName.CY_DATA_EFFECT]: 'Escuchar cambios en los data points o parámetros especificados',
      [ViewModel.DisplayName.CY_ACTION_EFFECT]: 'Configura las acciones que se dispararán cuando se realicen todas las demás acciones',
      [ViewModel.DisplayName.CY_LOAD_EFFECT]: 'Acción disparada cuando el componente se muestre, p.ej. cuando el evento está en un modal y éste se abre',
      [ViewModel.DisplayName.CY_UNLOAD_EFFECT]:
        'Acción disparada cuando el componente deje de mostrarse p.ej. cuando el evento está en un layout y éste pasa a estar oculto',
      [ViewModel.DisplayName.CY_INTERVAL_EFFECT]: 'Dispara una acción en un intérvalo de tiempo determinado',
      [ViewModel.DisplayName.CY_REUSABLE_ACTION_EFFECT]:
        'Configurar acciones para reusarlas en multiples componentes, disparadas a través de una acción "use action" en un componente separado o a través de una acción de refresco',
      [ViewModel.DisplayName.CY_BLANK]: 'Insertar en las columnas para mantenerlas vacias',
      [ViewModel.DisplayName.CY_ACTION]: 'Inserta botones y enlaces',
      [ViewModel.DisplayName.CY_TEXT]: 'Crear areas de texto, con opción a botones',
      [ViewModel.DisplayName.CY_IMAGE]: 'Crear bloques de imágenes que pueden contener imágenes, texto y botones',
      [`${ViewModel.DisplayName.CY_LAYOUT}-${ViewModel.LayoutType.RESPONSIVE}`]: 'Mostrar eficientemente multiples KPIs en el mínimo espacio',
      [`${ViewModel.DisplayName.CY_LIST}-${ViewModel.CyListType.LIST}`]: 'Resumen simple y compacto de un conjunto de registros en una lista vertical',
      [`${ViewModel.DisplayName.CY_LIST}-${ViewModel.CyListType.TABLE}`]: 'Campos relevantes de un conjunto de registros mostrados en una tabla',
      [`${ViewModel.DisplayName.CY_LIST}-${ViewModel.CyListType.CARD}`]: 'Resumen de registros apilados en cartas',
      [`${ViewModel.DisplayName.CY_LIST}-${ViewModel.CyListType.GRID}`]: 'Presentación visual de los registros mostrando título, imagen y color',
      [`${ViewModel.DisplayName.CY_FORM}-${ViewModel.CyFormType.DEFAULT}`]: 'Mostrar todo el formulario en una sola área',
      [`${ViewModel.DisplayName.CY_FORM}-${ViewModel.CyFormType.STEPPER}`]: 'Mostrar cada grupo de la colección de datos como un paso del formulario',
      dynamicLayout: {
        title: 'Dynamic',
        longDesc: 'A layout that will adapt its format, based on the content'
      }
    }
  },
  RichTextEditor: {
    undo: 'Deshacer',
    redo: 'Rehacer',
    formatBold: 'Negrita',
    formatItalic: 'Cursiva',
    formatUnderline: 'Subrayado',
    formatStrikethrough: 'Tachado',
    formatSubscript: 'Subíndice',
    formatSuperscript: 'Superíndice',
    clearFormatting: 'Borrar formato',
    formatTextColor: 'Cambiar color del texto',
    formatTextBgColor: 'Cambiar color de fondo del texto',
    formatCode: 'Formato de código',
    formatLink: 'Formato de enlace',
    formatQuote: 'Formato de cita',
    formatBullet: 'Formato de lista',
    formatNumber: 'Formato de lista numerada',
    formatCheck: 'Lista de verificación',
    alignLeft: 'Alinear a la izquierda',
    alignRight: 'Alinear a la derecha',
    alignCenter: 'Alinear al centro',
    alignJustify: 'Justificar',
    indentIncrease: 'Aumentar sangría',
    indentDecrease: 'Disminuir sangría',
    placeholderValue: 'Escribe texto enriquecido',
    infoBlock: 'Bloque de información',
    warningBlock: 'Bloque de advertencia',
    alertBlock: 'Bloque de alerta',
    successBlock: 'Bloque de éxito'
  }
};
