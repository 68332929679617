import { styleHelpers } from '@utils/styleHelpers';
import { styles } from './styles';
import { Icon } from '@components/elements/Icon';
import { COLOR } from '@constants';
import React, { memo, useEffect, useState } from 'react';
import { SearchInput } from '@components/elements/SearchInput';
import { useOutsideClick } from '@utils';
import { useSearchStore } from '../../../../store/SearchStore';
import { SidebarNavStatus, useNavigationMenuStore } from '../../../../store/NavigationMenuStore';

const NavigationMenuSearchComponent = ({ navMenuRef }) => {
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [isKeyboardTriggered, setIsKeyboardTriggered] = useState(false);

  const isHidden = useNavigationMenuStore(state => state.status === SidebarNavStatus.HIDDEN);
  const isExpanded = useNavigationMenuStore(state => state.status === SidebarNavStatus.EXPANDED);

  const searchQuery = useSearchStore(state => state.searchQuery);
  const setSearchQuery = useSearchStore(state => state.setSearchQuery);

  const ref = useOutsideClick(() => {
    setTimeout(() => setSearchQuery(''), 500);
    setIsSearchVisible(false);
  }, 'mousedown');

  useEffect(() => {
    if (isHidden || !isExpanded) setIsSearchVisible(false);
  }, [isHidden, isExpanded]);

  useEffect(() => {
    const handleKeydown = (event: KeyboardEvent) => {
      if (!navMenuRef.current?.contains(document.activeElement) || isHidden || !isExpanded) return;

      if (event.key.length === 1) {
        if (!isSearchVisible) {
          setIsSearchVisible(true);
          setIsKeyboardTriggered(true);
        }
      }
    };

    document.addEventListener('keydown', handleKeydown);
    return () => document.removeEventListener('keydown', handleKeydown);
  }, [isSearchVisible, isHidden, isExpanded, setIsKeyboardTriggered, setIsSearchVisible, navMenuRef]);

  useEffect(() => {
    !isSearchVisible && setIsKeyboardTriggered(false);
  }, [isSearchVisible]);

  useEffect(() => {
    if (!searchQuery && isKeyboardTriggered) {
      setIsSearchVisible(false);
      navMenuRef.current?.focus();
    }
  }, [searchQuery]);

  if (!isExpanded) return;

  return (
    <div css={styles.container} onClick={() => setIsSearchVisible(true)}>
      {isSearchVisible && (
        <div css={styles.searchInputWrapper} ref={ref}>
          <SearchInput value={searchQuery} placeholder="" autoFocus={true} onChange={setSearchQuery} searchDelay={300} />
        </div>
      )}
      {!isSearchVisible && (
        <div css={[styleHelpers.flexCenter, styles.searchIconWrapper]}>
          <Icon name="search" size="24px" fill={COLOR.NEUTRAL_1} />
        </div>
      )}
    </div>
  );
};

export const NavigationMenuSearch = memo(NavigationMenuSearchComponent);

const NoSearchResultsPlaceholderComponent = () => {
  const { searchQuery, builderHasResults, appsHaveResults } = useSearchStore();
  const isSomeResults = builderHasResults || appsHaveResults;

  if (isSomeResults || !searchQuery) return null;

  return (
    <div css={styles.noSearchPlaceholder}>
      <p css={styles.noSearchParagraph}>
        No results for '<span css={styles.boldSearch}>{searchQuery}</span>'
      </p>
    </div>
  );
};

export const NoSearchResultsPlaceholder = memo(NoSearchResultsPlaceholderComponent);
