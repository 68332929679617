import { css } from '@emotion/react';

import { APP_WIDTH, COLOR, CONTAINER_WIDTH, DROPDOWN_BASE_STYLES, FONT_SIZE, FONT_WEIGHT, GAP } from '@constants';

export const styles = {
  mainContainer: css`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  container: css`
    display: flex;
    justify-content: center;
    align-items: flex-start;
  `,
  innerWrapper: css`
    flex-grow: 1;
    max-width: ${CONTAINER_WIDTH.M}px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: ${GAP.M};
  `,
  widthLimit: css`
    align-self: center;
    display: flex;
    flex-direction: column;
  `,
  section: css`
    overflow: auto;
    max-height: calc(90vh - 150px);
  `,
  emptyWrapper: css`
    margin: 0 auto;
    max-width: 280px;
    margin-top: ${GAP.XXL};
  `,
  resultsContainer: css`
    display: grid;
    grid-gap: ${GAP.M};
    grid-template-columns: minmax(0, auto) 250px;
  `,
  externalContainer: css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  externalDropdownContainer: css`
    width: 100%;
  `,
  externalSearches: css`
    ${DROPDOWN_BASE_STYLES};
    background-color: ${COLOR.BASE_BACKGROUND};
    padding: ${GAP.L} ${GAP.M};
    @media (max-width: ${APP_WIDTH.S - 1}px) {
      position: absolute;
      width: 90vw;
      left: 5vw;
    }
  `,
  mainResultListContainer: css`
    display: flex;
    flex-direction: column;
    gap: ${GAP.M};
  `,
  resultListContainer: css`
    padding: ${GAP.M} ${GAP.XL};
  `,
  resultHeader: css`
    display: flex;
    flex-direction: column;
    gap: ${GAP.S};
    padding-left: ${GAP.S};
    border-left: 1px ${COLOR.NEUTRAL_4} solid;
    align-items: flex-start;
  `,
  resultTitleContainer: css`
    display: flex;
    gap: ${GAP.XS};
  `,
  resultTitle: css`
    color: ${COLOR.NEUTRAL_2};
    font-size: ${FONT_SIZE.M};
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
    text-align: left;
  `,
  resultDescription: css`
    color: ${COLOR.NEUTRAL_2};
    font-size: ${FONT_SIZE.S};
    font-weight: ${FONT_WEIGHT.NORMAL};
    text-align: left;
  `,
  refiningContainer: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: ${GAP.M};
  `,
  recentSearchesContainer: css`
    margin: auto;
  `
};
