import { configureStore } from '@reduxjs/toolkit';

import { contextReducer } from './context';
import { globalReducer } from './global';
import { favoriteReducer } from './favorite';
import { notificationReducer } from './notification';
import { uiReducer } from './ui';
import { userReducer } from './user';
import { viewReducer } from './view';

export const store = configureStore({
  reducer: {
    context: contextReducer,
    user: userReducer,
    global: globalReducer,
    view: viewReducer,
    notification: notificationReducer,
    ui: uiReducer,
    favorite: favoriteReducer
  }
});
