import type { GeneralModel } from '@cyferd/client-engine';
import { ClientEngineContext } from '@cyferd/client-engine';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { IconImage } from '@components/elements/Icon/renderIcon';
import { DropdownMenu } from '@components/elements/DropdownMenu';
import { OptionMenuDropdown, OptionMenuDropdownType } from '@components/elements/OptionMenuDropdown';
import { CyWrapperContext } from '@components/smart/CyWrapper';
import { COLOR, FOREGROUND_COLOR } from '@constants';
import { styles } from './styles';
import { useContextApp } from './useContextApp';

const testid = 'view-usage-in-apps';

export const ViewUsageInApps = () => {
  const { useViewSelector } = useContext(ClientEngineContext);
  const { useAction } = useContext(CyWrapperContext);

  const view = useViewSelector();
  const { getContextAppId, setContextAppId, removeContextAppId } = useContextApp();

  const onNavigateTo = useAction('dispatchNavigateTo');
  const onClickItem = useCallback((id: string) => onNavigateTo({ path: 'APP_HOME', qs: { id } }), [onNavigateTo]);

  const apps = useMemo(
    () =>
      view?.apps?.map(app => ({
        id: app.id,
        // TODO: Remove the "|| (app as any).name" once the backend complies the globalView.apps iface
        label: app.recordTitle || (app as any).name,
        tooltip: app.recordDescription,
        image: (app.recordImage as GeneralModel.IconName) || 'apps',
        color: (app.recordColor as GeneralModel.Color.ThemeColor) || 'BRAND_1',
        onClick: () => onClickItem(app.id)
      })) || [],
    [view?.apps, onClickItem]
  );

  const contextAppId = getContextAppId();

  const currentApp = useMemo(() => {
    return apps.find(app => app.id === contextAppId) || apps[0];
  }, [apps, contextAppId]);

  useEffect(() => {
    if (!currentApp) {
      removeContextAppId();
    } else if (currentApp.id !== contextAppId) {
      setContextAppId(currentApp.id);
    }
  }, [currentApp, contextAppId]);

  if (!apps.length) return null;

  const otherApps = apps.filter(app => app.id !== currentApp.id).sort((a, b) => a.label.localeCompare(b.label));

  return (
    <div css={styles.container} data-testid={testid}>
      <div css={styles.mainApp} onClick={currentApp.onClick}>
        <div css={styles.iconContainer} style={{ backgroundColor: COLOR[currentApp.color] }}>
          <IconImage
            icon={currentApp.image}
            title={currentApp.label}
            iconProps={{ size: '20px', fill: FOREGROUND_COLOR[currentApp.color] }}
            imageProps={{ css: styles.image }}
          />
        </div>
        <div css={styles.title} data-testid={`${testid}-main-app-title`}>
          {currentApp.label}
        </div>
      </div>
      {apps?.length > 1 && (
        <DropdownMenu
          vertical={12}
          renderTrigger={({ onClick, ref, testid }) => (
            <div css={[styles.triggerContainer]} onClick={onClick} ref={ref} data-testid={testid}>
              + {apps.length - 1}
            </div>
          )}
        >
          <OptionMenuDropdown optionList={otherApps} type={OptionMenuDropdownType.APPS} />
        </DropdownMenu>
      )}
    </div>
  );
};
