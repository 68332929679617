import { useContext, useMemo } from 'react';

import type { CollectionModel, TagModel } from '@cyferd/client-engine';
import { ApiModel, GeneralModel, ViewModel, useTranslate } from '@cyferd/client-engine';
import { useTestingHelper } from '@utils';

import { TRANS, isSavedRecord } from '@constants';
import { CustomViewSelector } from '../../../shared/CustomViewSelector';
import type { EditorContextValue } from '../../../shared/EditorHome';
import { EditorContext } from '../../../shared/EditorHome';
import { EmptyState } from '@components/elements/EmptyState';
import { SchemaForm } from '../../../shared/SchemaForm';
import { styles } from './styles';
import { Layout } from '@components/elements/Layout';
import { Fieldset } from '@components/elements/Fieldset';
import { getPartialViewSchema, viewInputList } from '@models/view';
import { Left, Right, Top } from '../../../../../assets/partials';
import { userInputList } from '@models/user';

const coreViewsConfigSchema: GeneralModel.JSONSchema = {
  type: 'object',
  label: ' ',
  metadata: { fieldSize: GeneralModel.FieldSize.FULL },
  properties: {
    APP_HOME: {
      label: ' ',
      type: 'object',
      metadata: { detailGroupId: 'APP_HOME' },
      properties: {
        top: getPartialViewSchema(TRANS.client.fields.titles.above, <Top />),
        left: getPartialViewSchema(TRANS.client.fields.titles.left, <Left />),
        right: getPartialViewSchema(TRANS.client.fields.titles.right, <Right />)
      }
    }
  }
};

const inputList = [...userInputList, ...viewInputList];

export const ViewSettings = () => {
  const { translate } = useTranslate();
  const { getTestIdProps } = useTestingHelper('general-info');
  const { item, setItem } = useContext<EditorContextValue<TagModel.Tag>>(EditorContext);

  const detailGroupList: CollectionModel.Collection['detailGroupList'] = useMemo(
    () => [{ id: 'APP_HOME', image: 'table_rows_narrow', name: translate(TRANS.client.groups.titles.coreViewsConfig, { view: 'App home' }) }],
    [translate]
  );

  if (!item) return <EmptyState />;
  const isNew = !isSavedRecord(item);

  return (
    <div {...getTestIdProps('container')} className={styles.container}>
      <Layout type={ViewModel.LayoutType.FULL}>
        <Layout type={ViewModel.LayoutType.FULL}>
          <div>
            <Layout
              type={ViewModel.LayoutType.FULL}
              title={TRANS.client.groups.titles.customizeCoreViews}
              subtitle={TRANS.client.groups.descriptions.customizeCoreViews}
            >
              <SchemaForm
                wrapDetailGroups={true}
                schema={coreViewsConfigSchema}
                detailGroupList={detailGroupList}
                inputList={inputList}
                value={item?.coreViewsConfig}
                onChange={/* istanbul ignore next */ coreViewsConfig => setItem({ ...item, coreViewsConfig })}
              />
            </Layout>
          </div>
        </Layout>
        {!isNew && (
          <Layout
            type={ViewModel.LayoutType.FULL}
            title={TRANS.client.groups.titles.replaceCoreViews}
            subtitle={TRANS.client.groups.descriptions.replaceCoreViews}
          >
            <Fieldset title="Select a view to replace" isDetailGroup={true}>
              <CustomViewSelector
                title={`${translate(TRANS.client.fields.titles.appHomeView)} (${translate(TRANS.client.fields.descriptions.optional)})`}
                item={item}
                onChange={setItem}
                collectionId={ApiModel.ApiEntity.TAG}
                tag="APP_HOME"
                associationKey="appHomeView"
              />
            </Fieldset>
          </Layout>
        )}
      </Layout>
    </div>
  );
};
