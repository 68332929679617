import type { DropResult } from '@hello-pangea/dnd';
import { DragDropContext } from '@hello-pangea/dnd';
import { get } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { ErrorBoundary, ViewModel, getClassnames, noop, removeDeep, replaceDeep, useTranslate } from '@cyferd/client-engine';
import { useTestingHelper } from '@utils';

import { CTA, CTAType } from '@components/elements/CTA';
import { useNavigateToDoc } from '@components/elements/Docs/resources';
import { EmptyState } from '@components/elements/EmptyState';
import { getStateAfterDrop } from '@components/elements/Evaluator/resources';
import { Layout } from '@components/elements/Layout';
import { SearchInput } from '@components/elements/SearchInput';
import { TabList } from '@components/elements/TabList';
import { GENERAL, QUERY_PARAM, TRANS } from '@constants';
import { cloneNode, getNewNode } from '@utils/cloneNode';
import { getViewNodeList } from '@utils/getViewNodeList';
import { useQueryParamState } from '@utils/useQueryParamState';
import { GlobalContext } from '../../../../state-mgmt/GlobalState';
import type { EditorContextValue } from '../../../shared/EditorHome';
import { EditorContext } from '../../../shared/EditorHome';
import { NodeCard } from '../../../shared/NodeCard';
import { ViewTree } from '../ViewTree';
import { getFlatNodeList } from './getFlatNodeList';
import { styles } from './styles';

export const LayoutEditor = () => {
  const { translate } = useTranslate();
  const { getTestIdProps } = useTestingHelper('view-editor');
  const { deps } = useContext(GlobalContext);
  const [queryParamState, setQueryParamState] = useQueryParamState();
  const { item, setItem } = useContext<EditorContextValue<ViewModel.View>>(EditorContext);
  const activeNodeId = queryParamState[QUERY_PARAM.ACTIVE_NODE_ID];
  const [highlightedId, setHighlightedId] = useState<string>();
  const [activeTab, onChangeTab] = useState<string>('contains');
  const [search, setSearch] = useState<string>('');
  const onNavigateToDoc = useNavigateToDoc();

  const flatNodeList = useMemo(() => getFlatNodeList(item), [item]);

  const safeActiveNodeId = useMemo(() => flatNodeList.some(({ node }) => node?.id === activeNodeId) && activeNodeId, [activeNodeId, flatNodeList]);
  const headerElement = useMemo(
    () => ({ id: item?.header?.id, component: ViewModel.DisplayName.VIEW_HEADER, attrs: { ...item?.header }, listeners: {}, contains: [] }),
    [item?.header]
  );

  const navigationElement = useMemo(
    () => ({
      id: `${item?.header?.id}-globalHeader`,
      component: ViewModel.DisplayName.GLOBAL_HEADER,
      attrs: { ...item?.globalHeader },
      listeners: {},
      contains: []
    }),
    [item?.globalHeader, item?.header]
  );

  const viewNodeList = useMemo(() => getViewNodeList(item), [item]);

  const toggleActiveNodeId = useCallback(
    (id: string) => {
      setQueryParamState(prev => ({ ...prev, [QUERY_PARAM.ACTIVE_NODE_ID]: prev[QUERY_PARAM.ACTIVE_NODE_ID] === id ? null : id }));
      /* istanbul ignore next line */
      if (id !== safeActiveNodeId) {
        setTimeout(() => document.getElementById?.(`node-${id}`)?.scrollIntoView?.(), 300);
      }
    },
    [safeActiveNodeId, setQueryParamState]
  );

  const onChange = useCallback(
    (child: ViewModel.Node) => setItem({ ...item, contains: item.contains.map(c => (c.id === child.id ? child : c)) }),
    [item, setItem]
  );

  const onChangeHeader = useCallback((child: ViewModel.Node) => setItem({ ...item, header: child.attrs }), [item, setItem]);

  const onChangeNavigation = useCallback(
    (child: ViewModel.Node) => {
      setItem({ ...item, globalHeader: child.attrs });
    },
    [item, setItem]
  );

  const onChangeModal = useCallback(
    (modalId: string, child: ViewModel.Node) => setItem({ ...item, modals: { ...item.modals, [modalId]: child } }),
    [item, setItem]
  );

  /* istanbul ignore next line */
  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (!result?.draggableId || !result?.destination) return;
      const existingNodePointer = result?.draggableId?.substring(36 /** uuid length */, result?.draggableId?.length);

      const movingNode = get(item, existingNodePointer);
      const index: number = result.destination.index;
      const pointer = result?.destination?.droppableId?.substring(36 /** uuid length */, result?.destination?.droppableId?.length);
      const pointedNode: ViewModel.Node = get(removeDeep(item, existingNodePointer), pointer);
      const pointedValue = pointedNode?.contains || [];

      const newPointedValue = [...pointedValue.slice(0, index), movingNode, ...pointedValue.slice(index, pointedValue.length)].filter(Boolean);

      setItem(replaceDeep(removeDeep(item, existingNodePointer), newPointedValue, `${pointer}.contains`));
    },
    [item, setItem]
  );

  const onMove = useCallback(
    (currentPath: string, newPath: string) => {
      const result = getStateAfterDrop(item, { draggableId: currentPath, destination: { droppableId: `${newPath}.contains.0`, index: 0 } });
      setItem(result);
    },
    [item, setItem]
  );

  const onCloneComponent = useCallback(
    (pointer: string, original: ViewModel.Node) => {
      const newNode = cloneNode(original, translate);
      const containerNode = get(item, pointer);
      if (containerNode) setItem(replaceDeep(item, { ...containerNode, contains: [...containerNode.contains, newNode] }, pointer));
      else setItem({ ...item, contains: [...item.contains, newNode] });
      toggleActiveNodeId(newNode.id);
    },
    [item, setItem, toggleActiveNodeId, translate]
  );

  const onAddComponent = useCallback(
    (pointer: string, newNode: ViewModel.Node) => {
      const containerNode = get(item, pointer);
      setItem(replaceDeep(item, { ...containerNode, contains: [...containerNode.contains, newNode] }, pointer));
      toggleActiveNodeId(newNode.id);
    },
    [item, setItem, toggleActiveNodeId]
  );

  const onAddModal = useCallback(() => {
    const modal = getNewNode(ViewModel.DisplayName.CY_MODAL, translate);
    const contains = getNewNode(ViewModel.DisplayName.CY_UNLOAD_EFFECT, translate);
    setItem({ ...item, modals: { ...item.modals, [modal.id]: { ...modal, contains: [contains] } } });
    toggleActiveNodeId(modal.id);
  }, [item, setItem, toggleActiveNodeId, translate]);

  const onAddLayout = useCallback(() => {
    const layout = getNewNode(ViewModel.DisplayName.CY_LAYOUT, translate);
    setItem({ ...item, contains: [...item.contains, layout] });
    toggleActiveNodeId(layout.id);
  }, [item, setItem, toggleActiveNodeId, translate]);

  const onRemoveFlat = useCallback(
    (pathToRemove: string) => {
      const node = get(item, pathToRemove);
      deps.modalInteraction.onConfirm(
        {
          status: ViewModel.Status.INFO,
          icon: 'delete',
          title: `Delete "${node?.component}"?`,
          description: '',
          confirmLabel: TRANS.client.buttons.delete
        },
        () => setItem(removeDeep(item, pathToRemove))
      );
    },
    [deps.modalInteraction, item, setItem]
  );

  const onRemove = useCallback(
    (child: ViewModel.Node) => {
      deps.modalInteraction.onConfirm(
        {
          status: ViewModel.Status.INFO,
          icon: 'delete',
          title: `Delete "${child.component}"?`,
          description: '',
          confirmLabel: TRANS.client.buttons.delete
        },
        () => setItem({ ...item, contains: item.contains?.filter(c => c !== child) })
      );
    },
    [deps.modalInteraction, item, setItem]
  );

  useEffect(() => {
    const flatNodeItem = flatNodeList.find(({ node }) => safeActiveNodeId === node?.id);
    const pathOrigin = flatNodeItem?.path?.[0];
    const correctTab = pathOrigin === 'modals' ? 'modals' : 'contains';
    if (activeTab !== correctTab) onChangeTab(correctTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [safeActiveNodeId]);

  useEffect(() => {
    /** if there is no "mouse out event" this makes sure highlighted node doesn't get stuck */
    setHighlightedId(undefined);
  }, [item]);

  if (!item) return <EmptyState />;

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Layout itemHeight={ViewModel.LayoutHeightPreset.REMAINING}>
        <div {...getTestIdProps('container')} className={styles.mainContainer}>
          <div className={styles.sidebar}>
            <div>
              <SearchInput onChange={setSearch} value={search} />
            </div>
            <div className={styles.nodesContainer}>
              <ErrorBoundary>
                <ViewTree
                  flatNodeList={flatNodeList}
                  activeNodeId={safeActiveNodeId}
                  highlightedId={highlightedId}
                  search={search}
                  onToggleActiveNodeId={toggleActiveNodeId}
                  onAdd={onAddComponent}
                  onRemove={onRemoveFlat}
                />
              </ErrorBoundary>
            </div>
          </div>
          <div className={getClassnames(styles.container, !safeActiveNodeId && styles.fullCanvas)}>
            <div className={styles.nodesContainer}>
              <TabList
                activeTab={activeTab}
                tabList={[
                  { title: 'contains', displayName: TRANS.client.nav.builder.tabs.mainView },
                  {
                    title: 'modals',
                    displayName: Object.keys(item.modals).length
                      ? translate(TRANS.client.nav.builder.tabs.modalsCount, { count: Object.keys(item.modals).length })
                      : translate(TRANS.client.nav.builder.tabs.modals)
                  }
                ]}
                onChangeTab={onChangeTab as any}
              />
              <div className={styles.tabContent}>
                <div className={getClassnames(styles.viewTab, activeTab === 'contains' && styles.viewTabActive)}>
                  <Layout type={ViewModel.LayoutType.FULL}>
                    <div className={styles.content}>
                      <ErrorBoundary>
                        <NodeCard
                          node={navigationElement}
                          activeNodeId={safeActiveNodeId}
                          highlightedId={highlightedId}
                          toggleActiveNodeId={toggleActiveNodeId}
                          toggleHighlightedNodeId={setHighlightedId}
                          onAdd={noop}
                          onChange={onChangeNavigation}
                          onRemove={noop}
                          onMove={noop}
                          onClone={noop}
                          canClone={false}
                          canRemove={false}
                          path="globalHeader"
                          viewNodeList={viewNodeList}
                          allowsMove={false}
                        />
                      </ErrorBoundary>
                      <ErrorBoundary>
                        <NodeCard
                          node={headerElement}
                          activeNodeId={safeActiveNodeId}
                          highlightedId={highlightedId}
                          toggleActiveNodeId={toggleActiveNodeId}
                          toggleHighlightedNodeId={setHighlightedId}
                          onAdd={noop}
                          onChange={onChangeHeader}
                          onRemove={noop}
                          onMove={noop}
                          onClone={noop}
                          canClone={false}
                          canRemove={false}
                          path="header"
                          viewNodeList={viewNodeList}
                          allowsMove={false}
                        />
                      </ErrorBoundary>
                      {item.contains?.map((child, index) => (
                        <ErrorBoundary key={child.id}>
                          <NodeCard
                            node={child}
                            activeNodeId={safeActiveNodeId}
                            highlightedId={highlightedId}
                            toggleActiveNodeId={toggleActiveNodeId}
                            toggleHighlightedNodeId={setHighlightedId}
                            onAdd={onAddComponent}
                            onChange={onChange}
                            onRemove={onRemove}
                            onMove={onMove}
                            onClone={onCloneComponent}
                            canRemove={true}
                            canClone={true}
                            path={`contains.${index}`}
                            viewNodeList={viewNodeList}
                            allowsMove={true}
                            onNavigateToDoc={onNavigateToDoc}
                          />
                        </ErrorBoundary>
                      ))}
                      {!item.contains?.length && (
                        <div className={styles.addBtnContainer}>
                          <CTA
                            type={CTAType.SECONDARY}
                            label="Add layout"
                            icon="add"
                            onClick={onAddLayout}
                            testid={getTestIdProps('add-layout-btn')['data-testid']}
                          />
                        </div>
                      )}
                    </div>
                  </Layout>
                </div>
                <div className={getClassnames(styles.viewTab, activeTab === 'modals' && styles.viewTabActive)}>
                  <Layout type={ViewModel.LayoutType.FULL}>
                    {Object.entries(item.modals).map(([modalId, modalNode]) => (
                      <div key={modalId}>
                        <div>
                          <ErrorBoundary>
                            <NodeCard
                              node={modalNode}
                              activeNodeId={safeActiveNodeId}
                              highlightedId={highlightedId}
                              toggleActiveNodeId={toggleActiveNodeId}
                              toggleHighlightedNodeId={setHighlightedId}
                              onChange={child => onChangeModal(modalId, child)}
                              onAdd={onAddComponent}
                              onRemove={node => onRemoveFlat(`modals.${node.id}`)}
                              onMove={onMove}
                              onClone={onCloneComponent}
                              canClone={false}
                              canRemove={true}
                              path={`modals.${modalId}`}
                              viewNodeList={viewNodeList}
                              allowsMove={false}
                              onNavigateToDoc={onNavigateToDoc}
                            />
                          </ErrorBoundary>
                        </div>
                      </div>
                    ))}
                    <div className={styles.addBtnContainer}>
                      <CTA type={CTAType.SECONDARY} label="Add modal" icon="add" onClick={onAddModal} testid={getTestIdProps('add-modal-btn')['data-testid']} />
                    </div>
                  </Layout>
                </div>
              </div>
            </div>
            <div className={styles.detailContainer} id={GENERAL.NODE_DETAIL_ID} />
          </div>
        </div>
      </Layout>
    </DragDropContext>
  );
};
