import { memo, useCallback, useMemo, useState } from 'react';

import { IconImage } from '@components/elements/Icon/renderIcon';
import { COLOR, FONT_SIZE } from '@constants';
import { ApiModel, ViewModel } from '@cyferd/client-engine';
import { useCyActions } from '@utils';
import { useLocation } from 'react-router-dom';
import type { INavigationList, IRenderRowWithChildren, NavigationMenuProps } from '../../types';
import { ItemMenu } from '../ItemMenu';
import { ItemMenuPopover } from '../ItemMenuPopover';
import { SubItemMenu } from '../SubItemMenu';
import { RecentViews } from './RecentViews';
import { styles } from './styles';

interface IAppNavigationWrapperProps {
  isOpen: boolean;
  handleActive: (id: string) => boolean;
  data: INavigationList;
  recentViews: NavigationMenuProps['recentViews'];
}

const MAX_LIST_ITEM = 17;

export const AppNavigationWrapper = memo(function AppNavigationWrapper({ isOpen, data, handleActive, recentViews = [] }: IAppNavigationWrapperProps) {
  const { onDispatchNavigateTo } = useCyActions();
  const [openId, setOpenId] = useState<string | null>(data.find(item => handleActive(item.id))?.id || null);
  const extracount: number | false = data.length > MAX_LIST_ITEM && data.length - MAX_LIST_ITEM;
  const showRecentViews = recentViews.length > 0;
  const onToggleOpenMore = useCallback((id: string) => {
    setOpenId(prevId => (prevId === id ? null : id));
  }, []);

  const onClick = useCallback(
    (appId: string) => {
      onDispatchNavigateTo({ path: `/APP_HOME?id=${appId}` });
      setOpenId(prevId => (prevId === appId ? null : appId));
    },
    [onDispatchNavigateTo]
  );

  const memoizedData = useMemo(() => {
    if (data.length <= MAX_LIST_ITEM || isOpen) return data;
    return data.slice(0, MAX_LIST_ITEM);
  }, [data, isOpen]);

  return (
    <>
      <div css={[styles.content, styles.mainContent]} data-testid="AppNavigationWrapper">
        {memoizedData.map(props => (
          <RenderRow
            {...props}
            onToggle={onToggleOpenMore}
            isShowMoreOpen={props.id === openId}
            onClick={onClick}
            key={props.id}
            isOpen={isOpen}
            isActive={handleActive(props.id)}
          />
        ))}
        {extracount && !isOpen && <div css={styles.extraCount}>+{extracount}</div>}
      </div>
      <div css={styles.footer}>
        {showRecentViews && (
          <RecentViews recentViews={recentViews} isOpen={isOpen} isShowMore={'RecentViews' === openId} onClick={() => onToggleOpenMore('RecentViews')} />
        )}
      </div>
    </>
  );
});

export const SideContent = (props: IRenderRowWithChildren & { viewActiveId?: string; collectionActiveId?: string }) => {
  const { collections, views, recordTitle, recordColor, recordImage, viewActiveId, onClick, collectionActiveId, isActive, id } = props;
  return (
    <>
      <div css={[styles.sideContent, isActive && styles.active]} onClick={() => onClick(id)}>
        <IconImage
          icon={recordImage || 'apps'}
          title=""
          iconProps={{ size: FONT_SIZE.L, fill: isActive ? COLOR[recordColor || 'BRAND_1'] : COLOR.NEUTRAL_1 }}
          imageProps={{ size: FONT_SIZE.L }}
        />
        <div>{recordTitle}</div>
      </div>
      <SubItemMenu viewActiveId={viewActiveId} collectionActiveId={collectionActiveId} collections={collections} views={views} renderFromPortal={true} />
    </>
  );
};

const RenderRow = memo(function RenderRow(props: IRenderRowWithChildren & { isShowMoreOpen: boolean; onToggle: (id: string) => void }) {
  const { pathname, search } = useLocation();
  const { onDispatchFormModal } = useCyActions();
  const { id, recordTitle, recordColor, recordImage, isActive, isOpen, onClick, collections, views, flows, isShowMoreOpen, onToggle } = props;
  const containChildren = [...collections, ...views].length > 0;
  const showMore = isShowMoreOpen && isOpen;
  const viewActiveId = pathname.replace('/', '');
  const collectionActiveId = new URLSearchParams(search).get('collectionId');

  const onFlowClick = useCallback(
    (flowId: string, event: unknown) => {
      onDispatchFormModal({ flowId, type: ApiModel.ApiEntity.FLOW, formType: ViewModel.CyFormType.STEPPER }, event);
    },
    [onDispatchFormModal]
  );

  const optionList = useMemo(
    () =>
      flows.map(flow => ({
        label: flow.recordTitle,
        image: flow.recordImage,
        color: flow.recordColor,
        onClick: (event: unknown) => onFlowClick(flow.id, event)
      })),
    [flows, onFlowClick]
  );

  return (
    <>
      <ItemMenuPopover
        content={<SideContent {...props} onClick={onClick} viewActiveId={viewActiveId} collectionActiveId={collectionActiveId} />}
        disabled={isOpen}
      >
        <ItemMenu
          id={id}
          views={views}
          collections={collections}
          isActive={isActive}
          isOpen={isOpen}
          onToggle={onToggle}
          onClick={onClick}
          isShowMore={isShowMoreOpen}
          containChildren={containChildren}
          recordTitle={recordTitle}
          recordColor={recordColor}
          recordImage={recordImage}
          optionList={optionList}
        />
      </ItemMenuPopover>
      <div css={showMore ? styles.show : styles.hide}>
        <SubItemMenu collectionActiveId={collectionActiveId} viewActiveId={viewActiveId} collections={collections} views={views} />
      </div>
    </>
  );
});
