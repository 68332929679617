import { CTAType } from '@components/elements/CTA';
import { type IOptionListItem, OptionMenu } from '@components/elements/OptionMenu';
import { PreventClickPropagation } from '@components/elements/PreventClickPropagation';
import { ViewModel } from '@cyferd/client-engine';
import { memo, useMemo } from 'react';
import { styles } from './styles';
import type { IconKeys } from '@components/elements/Icon';
import { HighlightedParagraph } from '..//HighlightedParagraph';
import { IconImage } from '@components/elements/Icon/renderIcon';

interface ISubItemValue {
  title: string;
  isActive: boolean;
  optionList?: IOptionListItem[];
  onClick: () => void;
  icon?: IconKeys;
  isOpen?: boolean;
}

const SubItemValue = memo(function SubItemValue({ onClick, isActive, title, optionList, icon, isOpen }: ISubItemValue) {
  const styling = useMemo(
    () => ({
      listItem: [styles.item, isActive && styles.activeOptionContent],
      paragraph: [styles.label, isActive && styles.activeText]
    }),
    [isActive]
  );

  const showOptionsList = Boolean(optionList?.length) && isOpen;

  return (
    <li onClick={onClick} css={styling.listItem}>
      {icon ? <IconImage icon={icon} iconProps={{ size: '24px', outlined: true }} imageProps={{ size: '24px' }} /> : <div css={styles.noIconPlaceholder} />}
      <HighlightedParagraph text={title} styles={styling.paragraph} />
      {showOptionsList && (
        <div css={styles.actionContainer} data-selector="subItemMenu-actionContainer">
          <PreventClickPropagation>
            <OptionMenu defaultBtnType={CTAType.ACTION_SECONDARY} defaultBtnSize={ViewModel.CTASize.SMALL} optionList={optionList} />
          </PreventClickPropagation>
        </div>
      )}
    </li>
  );
});

const SubItemMenuComponent = ({ isOpen = false, items = [], testid = '', isItemActive = (_id: string) => false }) => {
  return (
    <ul data-testid={testid || 'SubItemMenu'} css={styles.container}>
      {items.map(item => (
        <SubItemValue
          key={item.id || item.title}
          title={item.title}
          icon={item.icon}
          onClick={item.onClick}
          isActive={isItemActive?.(item.id)}
          optionList={item.optionsList}
          isOpen={isOpen}
        />
      ))}
    </ul>
  );
};

type MemoizedSubItemMenuType = typeof MemoizedSubItemMenu & {
  Value: typeof SubItemValue;
};
const MemoizedSubItemMenu = memo(SubItemMenuComponent);
export const SubItemMenu = MemoizedSubItemMenu as MemoizedSubItemMenuType;

SubItemMenu.Value = SubItemValue;
