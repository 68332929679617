import type { FC } from 'react';
import { memo } from 'react';

import type { WrappedComponent } from '@cyferd/client-engine';
import { ViewModel, CyViewContent } from '@cyferd/client-engine';

import { CyAction } from '@components/smart/CyAction';
import { CyActionEffect, CyDataEffect, CyIntervalEffect, CyLoadEffect, CyReusableActionEffect, CyUnloadEffect } from '@components/smart/CyEffect';
import { CyActivityLog } from '@components/smart/CyActivityLog';
import { CyBlank } from '@components/smart/CyBlank';
import { CyCalendar } from '@components/smart/CyCalendar';
import { CyChart } from '@components/smart/CyChart';
import { CyForm } from '@components/smart/CyForm';
import { CyImage } from '@components/smart/CyImage';
import { CyInfo } from '@components/smart/CyInfo';
import { CyKanban } from '@components/smart/CyKanban';
import { CyLayout } from '@components/smart/CyLayout';
import { CyList } from '@components/smart/CyList';
import { CyMap } from '@components/smart/CyMap';
import { CyModal } from '@components/smart/CyModal';
import { CySearch } from '@components/smart/CySearch';
import { CyTable } from '@components/smart/CyTable';
import { CyText } from '@components/smart/CyText';
import { CyView } from '@components/smart/CyView';

import { MappedComponent } from './MappedComponent';

const NotFound = ({ children }: React.PropsWithChildren) => children || null;

const getComponent = (Item: FC<any>) => {
  /* istanbul ignore next */
  const component = memo(props => (
    <MappedComponent {...props}>
      <Item {...props} />
    </MappedComponent>
  ));
  component.displayName = Item.displayName;
  return component;
};

export const componentRecord: { [_k in ViewModel.DisplayName | 'NotFound']: WrappedComponent } = {
  [ViewModel.DisplayName.CY_LIST]: getComponent(CyList),
  [ViewModel.DisplayName.CY_TABLE]: getComponent(CyTable),
  [ViewModel.DisplayName.CY_FORM]: getComponent(CyForm),
  [ViewModel.DisplayName.CY_CHART]: getComponent(CyChart),
  [ViewModel.DisplayName.CY_MODAL]: getComponent(CyModal),
  [ViewModel.DisplayName.CY_INFO]: getComponent(CyInfo),
  [ViewModel.DisplayName.CY_BLANK]: getComponent(CyBlank),
  [ViewModel.DisplayName.CY_LAYOUT]: getComponent(CyLayout),
  [ViewModel.DisplayName.CY_KANBAN]: getComponent(CyKanban),
  [ViewModel.DisplayName.CY_SEARCH]: getComponent(CySearch),
  [ViewModel.DisplayName.CY_ACTION]: getComponent(CyAction),
  [ViewModel.DisplayName.CY_TEXT]: getComponent(CyText),
  [ViewModel.DisplayName.CY_IMAGE]: getComponent(CyImage),
  [ViewModel.DisplayName.CY_CALENDAR]: getComponent(CyCalendar),
  [ViewModel.DisplayName.CY_DATA_EFFECT]: getComponent(CyDataEffect),
  [ViewModel.DisplayName.CY_ACTION_EFFECT]: getComponent(CyActionEffect),
  [ViewModel.DisplayName.CY_LOAD_EFFECT]: getComponent(CyLoadEffect),
  [ViewModel.DisplayName.CY_UNLOAD_EFFECT]: getComponent(CyUnloadEffect),
  [ViewModel.DisplayName.CY_INTERVAL_EFFECT]: getComponent(CyIntervalEffect),
  [ViewModel.DisplayName.CY_REUSABLE_ACTION_EFFECT]: getComponent(CyReusableActionEffect),
  [ViewModel.DisplayName.CY_VIEW]: getComponent(CyView),
  [ViewModel.DisplayName.CY_VIEW_CONTENT]: getComponent(CyViewContent),
  [ViewModel.DisplayName.CY_ACTIVITY_LOG]: getComponent(CyActivityLog),
  [ViewModel.DisplayName.CY_MAP]: getComponent(CyMap),
  NotFound,
  viewHeader: () => null,
  globalHeader: () => null
};
