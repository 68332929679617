import { COLOR, FONT_SIZE, FONT_WEIGHT, GAP, HC_COLOR, HC_SECONDARY_COLOR, RADIUS } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  container: css`
    width: 100%;
    padding: 0 ${GAP.XS};
  `,
  activeOptionContent: css`
    background-color: ${HC_SECONDARY_COLOR.BRAND_1};
    font-weight: ${FONT_WEIGHT.BOLD};
    color: ${HC_COLOR.BRAND_1};
    :hover {
      background-color: ${HC_SECONDARY_COLOR.BRAND_1};
      [data-selector='subItemMenu-actionContainer'] {
        display: block;
      }
    }
  `,
  item: css`
    color: ${COLOR.NEUTRAL_1};
    padding: 0 ${GAP.M} 0 30px;
    cursor: pointer;
    border-radius: ${RADIUS.S};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 6px;

    font-size: ${FONT_SIZE.S};
    font-weight: ${FONT_WEIGHT.NORMAL};
    height: 32px;

    :hover {
      background-color: ${COLOR.NEUTRAL_4};
      [data-selector='subItemMenu-actionContainer'] {
        display: block;
      }
    }
  `,
  title: css`
    height: ${GAP.XXL};
    padding-left: ${GAP.L};
    font-size: ${FONT_SIZE.XS};
    color: ${COLOR.NEUTRAL_2};
    font-weight: ${FONT_WEIGHT.BOLD};
    pointer-events: none;
  `,
  actionContainer: css`
    margin-left: auto;
    display: none;
  `,
  label: css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  `,
  noIconPlaceholder: css`
    visibility: hidden;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  `,
  activeText: css`
    font-weight: ${FONT_WEIGHT.BOLD};
  `
};
