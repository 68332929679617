import type { INavigationList } from '@components/platform/GlobalHeader/types';
import { useCyActions } from '@utils';
import { useCallback, useEffect, useMemo } from 'react';
import { styles } from './styles';
import { ApiModel, ViewModel } from '@cyferd/client-engine';
import { useGetNavigateTo } from '../../../../hooks/useGetNavigateTo';
import { useSearchStore } from '../../../../../../store/SearchStore';
import { useNavigationMenuStore } from '../../../../../../store/NavigationMenuStore';
import { AppRow } from './components/AppRow';
import { TRANS } from '@constants';

interface AppsProps {
  isOpen: boolean;
  data: INavigationList;
  isAppActive: (id) => boolean;
}

const MAX_LIST_ITEM = 17;

export const Apps = ({ isOpen, data, isAppActive }: AppsProps) => {
  const { getNavigateTo } = useGetNavigateTo();
  const { onDispatchFormModal } = useCyActions();

  const openItem = useNavigationMenuStore(state => state.openItem);

  const searchQuery = useSearchStore(state => state.searchQuery);
  const setAppsHaveResults = useSearchStore(state => state.setAppsHaveResults);

  const extracount: number | false = data.length > MAX_LIST_ITEM && data.length - MAX_LIST_ITEM;

  const memoizedData = useMemo(() => {
    if (!searchQuery) return data.slice(0, isOpen ? data.length : MAX_LIST_ITEM);

    const lowerSearch = searchQuery.toLowerCase();

    const filteredData = data.filter(({ recordTitle, views, collections }) => {
      if (recordTitle.toLowerCase().includes(lowerSearch)) return true;
      if (views.some(({ recordTitle: viewTitle }) => viewTitle.toLowerCase().includes(lowerSearch))) return true;
      if (collections.some(({ recordTitle: collectionTitle }) => collectionTitle.toLowerCase().includes(lowerSearch))) return true;
      return false;
    });

    return isOpen ? filteredData : filteredData.slice(0, MAX_LIST_ITEM);
  }, [data, isOpen, searchQuery]);

  useEffect(() => setAppsHaveResults(Boolean(memoizedData.length)), [memoizedData, setAppsHaveResults]);

  const onClick = useCallback(
    (appId: string) => getNavigateTo({ path: 'APP_HOME', qs: { id: appId }, contextAppId: appId }, undefined, () => openItem(appId)),
    [getNavigateTo, openItem]
  );

  const onRowNavigateToView = useCallback((viewId: string, contextAppId: string) => getNavigateTo({ path: viewId, contextAppId }), [getNavigateTo]);

  const onRowNavigateToCollection = useCallback(
    (collectionId: string, contextAppId: string) => getNavigateTo({ path: 'TABLE', qs: { collectionId }, contextAppId }),
    [getNavigateTo]
  );

  const onRowFlowClick = useCallback(
    (flowId: string, event: unknown) => {
      onDispatchFormModal({ flowId, type: ApiModel.ApiEntity.FLOW, formType: ViewModel.CyFormType.STEPPER }, event);
    },
    [onDispatchFormModal]
  );

  const onCollectionCreate = useCallback(
    (collectionId: string, event?: unknown) =>
      onDispatchFormModal(
        {
          type: ApiModel.ApiEntity.ENTITY,
          title: TRANS.client.buttons.createNew,
          formType: ViewModel.CyFormType.STEPPER,
          collectionId,
          recordActionsHidden: true
        },
        event
      ),
    [onDispatchFormModal]
  );

  return (
    <>
      <div css={styles.content} data-testid="AppNavigationWrapper">
        {memoizedData.map(props => (
          <AppRow
            {...props}
            onClick={onClick(props.id)}
            key={props.id}
            isOpen={isOpen}
            isActive={isAppActive(props.id)}
            onFlowClick={onRowFlowClick}
            onNavigateToCollection={onRowNavigateToCollection}
            onNavigateToView={onRowNavigateToView}
            _onCollectionCreate={onCollectionCreate}
          />
        ))}
        {extracount && !isOpen && <div css={styles.extraCount}>+{extracount}</div>}
      </div>
    </>
  );
};
