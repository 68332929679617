export const useContextApp = () => {
  const setContextAppId = (id?: string) => {
    const url = new URL(window.location.href);

    url.searchParams.set('contextAppId', id);

    history.replaceState(null, '', url.toString());
  };

  const removeContextAppId = () => {
    const url = new URL(window.location.href);

    url.searchParams.delete('contextAppId');

    history.replaceState(null, '', url.toString());
  };

  const getContextAppId = () => {
    const url = new URL(window.location.href);

    return url.searchParams.get('contextAppId');
  };

  return {
    setContextAppId,
    removeContextAppId,
    getContextAppId
  };
};
