import { Fragment } from 'react';

import type { ViewModel } from '@cyferd/client-engine';
import { Translate, isObject } from '@cyferd/client-engine';

import { styles } from './styles';

export const NodeTooltip = ({ node }: { node: ViewModel.Node }) => {
  const attrList = [
    'title',
    'description',
    'subtitle',
    'collectionId',
    'recordId',
    'type',
    'value',
    'model',
    'initialFetchCriteria',
    'headerListChildren',
    'actionListChildren'
  ]
    .map(key => {
      const value = (() => {
        const rawValue = node?.attrs?.[key];
        if (['string', 'number', 'boolean'].includes(typeof rawValue)) return String(rawValue);
        if (isObject(rawValue)) return '[complex]';
        if (Array.isArray(rawValue) && rawValue.length) return `[list ${rawValue.length}]`;
      })();
      return [key, value];
    })
    .filter(([_, value]) => !!value);
  return (
    <div className={styles.nodeTooltipContainer}>
      {!!node?.name && (
        <div data-testid="node-title" className={styles.nodeTitle}>
          <Translate>{node?.name}</Translate> ({node?.component})
        </div>
      )}

      <div className={styles.nodeTooltipTable}>
        {[['ID', node?.id], ...attrList].map(([key, value]) => {
          return (
            <Fragment key={key}>
              <strong className={styles.nodeTitle}>{key}</strong>
              <span data-testid="node-value" className={styles.nodeValue}>
                {value}
              </span>
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};
