import { useCyActions } from '@utils';
import { useCallback, useState } from 'react';
import { styles } from '../styles';
import { ItemMenuPopover } from './ItemMenuPopover';
import { ItemMenu } from './ItemMenu';
import { SubItemMenu } from '../../SubItemMenu';
import { BUILDER_ICON, BUILDER_ROUTE } from '@constants';
import { ErrorBoundary, ViewModel } from '@cyferd/client-engine';
import { Playground } from '../../../../../../../../builder/views/shared/Playground';
import { DevMenuData } from '@components/elements/DevMenuData';
import { DevMenuActions } from '@components/elements/DevMenuActions';
import { DevMenuInspector } from '@components/elements/DevMenuInspector';
import { Modal } from '@components/elements/Modal';
import { useNavigationMenuStore } from '../../../../../store/NavigationMenuStore';
import { DEV_TOOLS_ID } from '../constants';

export const DevTools = ({ isOpen }) => {
  const [status, setStatus] = useState(ModalStatus.IDLE);

  const { onDispatchNavigateTo } = useCyActions();

  const isSelected = useNavigationMenuStore(state => state.itemIds[DEV_TOOLS_ID]);
  const closeItem = useNavigationMenuStore(state => state.closeItem);
  const openItem = useNavigationMenuStore(state => state.openItem);

  const onClick = useCallback(() => (isSelected ? closeItem() : openItem(DEV_TOOLS_ID)), [isSelected, closeItem, openItem]);

  const onHandleModal = useCallback(
    (currentModal: ModalStatus) => setStatus(prevStatus => (prevStatus === currentModal ? ModalStatus.IDLE : currentModal)),
    []
  );

  const tools = [
    {
      title: 'Traffic',
      icon: 'compare_arrows',
      onClick: () => onHandleModal(ModalStatus.TRAFFIC)
    },
    {
      title: 'Data',
      icon: 'code_blocks',
      onClick: () => onHandleModal(ModalStatus.DATA)
    },
    {
      title: 'Resources',
      icon: 'account_tree',
      onClick: () => onHandleModal(ModalStatus.RESOURCES)
    },
    {
      title: 'Playground tools',
      icon: 'terminal',
      onClick: () => onHandleModal(ModalStatus.PLAYGROUND)
    },
    {
      title: 'Cyferd guide',
      icon: BUILDER_ICON.HELP,
      onClick: () => onDispatchNavigateTo({ path: BUILDER_ROUTE.GUIDE.ROOT })
    }
  ];

  const closeModal = useCallback(() => setStatus(ModalStatus.IDLE), []);

  return (
    <>
      <ItemMenuPopover disabled={isOpen} content={<SubItemMenu items={tools} />}>
        <ItemMenu
          onClick={onClick}
          optionList={[]}
          containChildren={true}
          isActive={false}
          recordTitle="Dev tools"
          isShowMore={isSelected}
          recordImage="terminal"
          isOpen={isOpen}
          onToggle={onClick}
          id={''}
        />
      </ItemMenuPopover>
      <div css={isOpen && isSelected ? styles.show : styles.hide}>
        <SubItemMenu items={tools} />
      </div>

      <ErrorBoundary>
        <Playground open={status === ModalStatus.PLAYGROUND} onClose={closeModal} />

        <Modal open={status === ModalStatus.DATA} title="Data" type={ViewModel.ModalType.FULL_SCREEN} onClose={closeModal}>
          <DevMenuData />
        </Modal>

        <Modal open={status === ModalStatus.TRAFFIC} title="Traffic" type={ViewModel.ModalType.FULL_SCREEN} onClose={closeModal}>
          <DevMenuActions />
        </Modal>

        <Modal open={status === ModalStatus.RESOURCES} title="Resources" type={ViewModel.ModalType.FULL_SCREEN} onClose={closeModal}>
          <DevMenuInspector />
        </Modal>
      </ErrorBoundary>
    </>
  );
};

enum ModalStatus {
  IDLE = 'idle',
  DATA = 'data',
  TRAFFIC = 'traffic',
  RESOURCES = 'resources',
  PLAYGROUND = 'playground'
}
