import { CTA, CTAType } from '@components/elements/CTA';
import { IconImage } from '@components/elements/Icon/renderIcon';
import { PreventClickPropagation } from '@components/elements/PreventClickPropagation';
import Skeleton from '@components/elements/Skeleton/Skeleton';
import { TabList, type TabListProps } from '@components/elements/TabList';
import { UIContext } from '@components/providers/UIprovider';
import { COLOR, THEME } from '@constants';
import { ErrorBoundary, ViewModel } from '@cyferd/client-engine';
import { getColorValue, useCyActions } from '@utils';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import CyferdBlack from '../../../../../assets/CyferdLogoBlack.svg';
import CyferdWhite from '../../../../../assets/CyferdLogoWhite.svg';
import { Playground } from '../../../../../builder/views/shared/Playground';
import type { NavigationMenuProps } from '../../types';
import { AppNavigationWrapper } from '../AppNavigationWrapper/AppNavigationWrapper';
import { BuilderContentWrapper } from '../BuilderContentWrapper/BuilderContentWrapper';
import { styles, newStyles } from './styles';

enum TabOptions {
  APPS = 'APPS',
  BUILDER = 'BUILDER'
}

const tabOptions = {
  APPS: {
    title: TabOptions.APPS,
    displayName: 'Apps'
  },
  BUILDER: {
    title: TabOptions.BUILDER,
    displayName: 'Builder'
  }
} as const;

export type { NavigationMenuProps };

export const NavigationSkeleton = ({ verticalOffset, verticalOffsetDirection }: Pick<NavigationMenuProps, 'verticalOffset' | 'verticalOffsetDirection'>) => (
  <div data-testid="navigation-skeleton">
    <div css={[styles.menu({ verticalOffset, verticalOffsetDirection }), styles.collapsedMenu]}>
      <div css={styles.loadinSkeleton}>
        <Skeleton.Avatar size={32} />
        <Skeleton.Content round={true} />
        <Skeleton.Content delay={0.3} round={true} />
        <Skeleton.Content delay={0.5} round={true} />
        <Skeleton.Content round={true} />
        <Skeleton.Content delay={0.3} round={true} />
      </div>
    </div>
  </div>
);

export const NavigationMenu = ({
  isOpen,
  onOpenChange,
  user,
  data = [],
  isLoading,
  verticalOffset,
  recentViews,
  verticalOffsetDirection
}: NavigationMenuProps) => {
  const { runtimeTheme } = useContext(UIContext);
  const { onDispatchNavigateTo } = useCyActions();
  const { pathname, search } = useLocation();
  const isBuilderRoute = pathname === '/ADMIN' || pathname.split('/')[1] === 'builder';
  const [isPlaygroundOpen, setPlaygoundOpen] = useState(false);
  const [tab, setTab] = useState<TabOptions>(isBuilderRoute ? TabOptions.BUILDER : TabOptions.APPS);

  const isAdmin = !!user?.roles?.length;
  const isActive = useCallback((id: string) => (id !== '/' ? pathname.startsWith(id) : pathname === id), [pathname]);
  const isAppActive = useCallback(
    (appId: string) => {
      const id = new URLSearchParams(search).get('id');
      return pathname === '/APP_HOME' && appId === id;
    },
    [pathname, search]
  );

  useEffect(() => {
    if (!isOpen && isAdmin) {
      setTab(prevState => {
        if (prevState === TabOptions.BUILDER && !isBuilderRoute) return TabOptions.APPS;
        if (prevState === TabOptions.APPS && isBuilderRoute) return TabOptions.BUILDER;
        return prevState;
      });
    }
  }, [isOpen, isBuilderRoute, isAdmin]);

  const handleClick = useCallback(() => onDispatchNavigateTo({ path: '/' }), [onDispatchNavigateTo]);
  const onTogglePlaygoundOpen = () => setPlaygoundOpen(p => !p);
  const onToggleOpen = () => onOpenChange(!isOpen);

  const defaultTabs = [tabOptions.APPS];
  const adminTabs = isAdmin ? [tabOptions.BUILDER] : [];
  const tabList = [...defaultTabs, ...adminTabs];

  const tenantLogo = useMemo(() => {
    if (user?.tenant?.styling?.logoLight) {
      return user.tenant.styling.logoLight;
    }
    return runtimeTheme === THEME.DARK || tab === TabOptions.BUILDER ? CyferdWhite : CyferdBlack;
  }, [user?.tenant?.styling?.logoLight, runtimeTheme, tab]);

  const builderStyles = useMemo(() => {
    if (tab === TabOptions.BUILDER && runtimeTheme === THEME.LIGHT) return styles.menuAlwaysDark;
    return null;
  }, [runtimeTheme, tab]);

  const colorToggleBtn = useMemo(() => {
    if (tab === TabOptions.BUILDER && runtimeTheme === THEME.LIGHT) {
      return '#99C7FF';
    }
    return getColorValue(COLOR.HC_15);
  }, [runtimeTheme, tab]);

  return (
    <Skeleton loading={isLoading} content={<NavigationSkeleton verticalOffset={verticalOffset} verticalOffsetDirection={verticalOffsetDirection} />}>
      <div css={styles.container}>
        <div
          css={[styles.menu({ verticalOffset, biggerGap: !isAdmin, verticalOffsetDirection }), !isOpen && styles.collapsedMenu, builderStyles]}
          data-testid="main-nav-content"
        >
          <div css={isOpen ? styles.headerExpanded : styles.headerCollapsed}>
            <div css={styles.avatarWrapper}>
              <CTA type={CTAType.SEEMLESS} onClick={handleClick} hideLoading={true}>
                <IconImage title="" icon={tenantLogo} iconProps={{ size: '32px' }} imageProps={{ css: styles.imageContent }} />
              </CTA>
              <p style={tab === TabOptions.BUILDER ? { color: '#ffffff' } : {}}>{user?.tenant?.name}</p>
            </div>
            {tabList.length > 1 && (
              <>
                <PreventClickPropagation containerCss={isOpen ? styles.toggleBtnExpanded : styles.toggleBtnCollapsed}>
                  <CTA
                    testid="AppNavigationWrapper-cta"
                    type={CTAType.LINK}
                    size={ViewModel.CTASize.LARGE}
                    onClick={onToggleOpen}
                    icon={{ name: isOpen ? 'keyboard_double_arrow_left' : 'keyboard_double_arrow_right', fill: colorToggleBtn }}
                  />
                </PreventClickPropagation>
                {!isOpen && <div css={styles.divider} />}
              </>
            )}
          </div>
          {isOpen && tabList.length > 1 && <TabList tabList={tabList} activeTab={tab} onChangeTab={setTab as TabListProps['onChangeTab']} />}
          <div css={[styles.containerTab, tab === TabOptions.BUILDER && { position: 'absolute', opacity: 0, pointerEvents: 'none' }]}>
            <AppNavigationWrapper isOpen={isOpen} handleActive={isAppActive} data={data} recentViews={recentViews} />
          </div>
          <div css={[styles.containerTab, tab === TabOptions.APPS && { position: 'absolute', opacity: 0, pointerEvents: 'none' }]}>
            <BuilderContentWrapper isOpen={isOpen} handleActive={isActive} onTogglePlaygoundOpen={onTogglePlaygoundOpen} />
          </div>
        </div>
      </div>
      <ErrorBoundary>
        <Playground open={isPlaygroundOpen} onClose={onTogglePlaygoundOpen} />
      </ErrorBoundary>
    </Skeleton>
  );
};

export default NavigationMenu;

export const NavigationSkeletonNewLayout = () => (
  <div data-testid="navigation-skeleton" css={[newStyles.menu, newStyles.collapsedMenu]}>
    <div css={styles.loadinSkeleton}>
      <Skeleton.Avatar size={32} />
      <Skeleton.Content round={true} />
      <Skeleton.Content delay={0.3} round={true} />
      <Skeleton.Content delay={0.5} round={true} />
      <Skeleton.Content round={true} />
      <Skeleton.Content delay={0.3} round={true} />
    </div>
  </div>
);

export const NavigationMenuNewLayout = ({ isOpen, onOpenChange, user, data = [], isLoading, recentViews }: NavigationMenuProps) => {
  const { runtimeTheme } = useContext(UIContext);
  const { onDispatchNavigateTo } = useCyActions();
  const { pathname, search } = useLocation();
  const isBuilderRoute = pathname === '/ADMIN' || pathname.split('/')[1] === 'builder';
  const [isPlaygroundOpen, setPlaygoundOpen] = useState(false);
  const [tab, setTab] = useState<TabOptions>(isBuilderRoute ? TabOptions.BUILDER : TabOptions.APPS);

  const isAdmin = !!user?.roles?.length;
  const isActive = useCallback((id: string) => (id !== '/' ? pathname.startsWith(id) : pathname === id), [pathname]);
  const isAppActive = useCallback(
    (appId: string) => {
      const id = new URLSearchParams(search).get('id');
      return pathname === '/APP_HOME' && appId === id;
    },
    [pathname, search]
  );

  useEffect(() => {
    if (!isOpen && isAdmin) {
      setTab(prevState => {
        if (prevState === TabOptions.BUILDER && !isBuilderRoute) return TabOptions.APPS;
        if (prevState === TabOptions.APPS && isBuilderRoute) return TabOptions.BUILDER;
        return prevState;
      });
    }
  }, [isOpen, isBuilderRoute, isAdmin]);

  const handleClick = useCallback(() => onDispatchNavigateTo({ path: '/' }), [onDispatchNavigateTo]);
  const onTogglePlaygoundOpen = () => setPlaygoundOpen(p => !p);
  const onToggleOpen = () => onOpenChange(!isOpen);

  const defaultTabs = [tabOptions.APPS];
  const adminTabs = isAdmin ? [tabOptions.BUILDER] : [];
  const tabList = [...defaultTabs, ...adminTabs];

  const tenantLogo = useMemo(() => {
    if (user?.tenant?.styling?.logoLight) {
      return user.tenant.styling.logoLight;
    }
    return runtimeTheme === THEME.DARK || tab === TabOptions.BUILDER ? CyferdWhite : CyferdBlack;
  }, [user?.tenant?.styling?.logoLight, runtimeTheme, tab]);

  const builderStyles = useMemo(() => {
    if (tab === TabOptions.BUILDER && runtimeTheme === THEME.LIGHT) return styles.menuAlwaysDark;
    return null;
  }, [runtimeTheme, tab]);

  const colorToggleBtn = useMemo(() => {
    if (tab === TabOptions.BUILDER && runtimeTheme === THEME.LIGHT) {
      return '#99C7FF';
    }
    return getColorValue(COLOR.HC_15);
  }, [runtimeTheme, tab]);

  return (
    <Skeleton loading={isLoading} content={<NavigationSkeletonNewLayout />}>
      <div css={newStyles.container}>
        <div css={[newStyles.menu, !isOpen && newStyles.collapsedMenu, builderStyles]} data-testid="main-nav-content">
          <div css={isOpen ? newStyles.headerExpanded : newStyles.headerCollapsed}>
            <div css={newStyles.avatarWrapper}>
              <CTA type={CTAType.SEEMLESS} onClick={handleClick} hideLoading={true}>
                <IconImage title="" icon={tenantLogo} iconProps={{ size: '32px' }} imageProps={{ css: newStyles.imageContent }} />
              </CTA>
              <p style={tab === TabOptions.BUILDER ? { color: '#ffffff' } : {}}>{user?.tenant?.name}</p>
            </div>
            <PreventClickPropagation containerCss={isOpen ? newStyles.toggleBtnExpanded : newStyles.toggleBtnCollapsed}>
              <CTA
                testid="AppNavigationWrapper-cta"
                type={CTAType.LINK}
                size={ViewModel.CTASize.LARGE}
                onClick={onToggleOpen}
                icon={{ name: isOpen ? 'keyboard_double_arrow_left' : 'keyboard_double_arrow_right', fill: colorToggleBtn }}
              />
            </PreventClickPropagation>
            {!isOpen && <div css={newStyles.divider} />}
          </div>
          {isOpen && tabList.length > 1 && <TabList tabList={tabList} activeTab={tab} onChangeTab={setTab as TabListProps['onChangeTab']} />}
          <div css={[newStyles.containerTab, tab === TabOptions.BUILDER && { position: 'absolute', opacity: 0, pointerEvents: 'none' }]}>
            <AppNavigationWrapper isOpen={isOpen} handleActive={isAppActive} data={data} recentViews={recentViews} />
          </div>
          <div css={[newStyles.containerTab, tab === TabOptions.APPS && { position: 'absolute', opacity: 0, pointerEvents: 'none' }]}>
            <BuilderContentWrapper isOpen={isOpen} handleActive={isActive} onTogglePlaygoundOpen={onTogglePlaygoundOpen} />
          </div>
        </div>
      </div>
      <ErrorBoundary>
        <Playground open={isPlaygroundOpen} onClose={onTogglePlaygoundOpen} />
      </ErrorBoundary>
    </Skeleton>
  );
};
