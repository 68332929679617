import { styles } from './styles';
import { useEffect, useState } from 'react';
import { DropdownOption } from '../DropdownOption';
import ReactDOM from 'react-dom';
import { Input } from '../Input';
import { useDropdownInteractions } from '@utils/useDropdownInteractions';

export interface DropDownOptionEditTableProps {
  value: string | number;
  options: any[];
  onChange: (value: string | number) => void;
  autoFocus: boolean;
  innerSetEditing?: (value: boolean) => void;
}

export const DROPDOWN_PORTAL_ID = 'dropdown-root';

export const DropDownOptionEditTable = ({ value, options, onChange, autoFocus = false, innerSetEditing }: DropDownOptionEditTableProps) => {
  const testid = 'DropDownOptionEditTable';

  const { handleKeyDown, setOpen, selectRef, highlightedIndex, isOpen, handleSelectItem } = useDropdownInteractions({
    autoFocus,
    options,
    initialHighlightedIndex: options.findIndex(option => option.value === value),
    onSelect: index => {
      onChange(options[index].value);
      innerSetEditing?.(false);
    }
  });

  return (
    <>
      <div ref={selectRef} onKeyDown={handleKeyDown} onClick={() => setOpen()} data-testid={testid} css={styles.container}>
        <Input autoFocus={autoFocus} noWrapperPadding={true} value={value} placeholder="Select an option" onChange={null} />
      </div>
      {isOpen && (
        <DropdownPortal containerRef={selectRef}>
          <div css={styles.listContainer}>
            {options
              .filter(({ hidden }) => !hidden)
              .map(({ label, description, image, color, value: internalValue }, index) => (
                <DropdownOption
                  highlight={highlightedIndex === index}
                  key={`${internalValue}-${index}`}
                  value={internalValue}
                  onClick={() => handleSelectItem(index)}
                  title={label || internalValue}
                  description={description}
                  image={image}
                  color={color}
                />
              ))}
            {!options.length && <DropdownOption title="No results found" testid={`${testid}-empty`} />}
          </div>
        </DropdownPortal>
      )}
    </>
  );
};

const DropdownPortal = ({ children, containerRef }) => {
  const [dropdownRoot, setDropdownRoot] = useState(null);

  useEffect(() => {
    const root = document.getElementById(DROPDOWN_PORTAL_ID) || document.body;
    setDropdownRoot(root);
  }, []);

  if (!dropdownRoot || !containerRef.current) return null;

  const { bottom, left } = containerRef.current.getBoundingClientRect();

  return ReactDOM.createPortal(
    <div
      style={{
        position: 'fixed',
        top: `${bottom}px`,
        left: `${left}px`
      }}
    >
      {children}
    </div>,
    dropdownRoot
  );
};
