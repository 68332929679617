import { createSlice } from '@reduxjs/toolkit';
import { ApiModel, GeneralModel, replaceDeep } from '@cyferd/client-engine';
import type { GeneralModel as ClientGeneralModel } from '@models';

import { NOTIFICATIONS_POINTER } from '../notification';

export const getInitialState = (): ClientGeneralModel.DataState => ({});

const globalSlice = createSlice({
  name: 'global',
  initialState: getInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(ApiModel.TriggerActionType.DISPATCH_SET_DATA, (state: ClientGeneralModel.DataState, action: ClientGeneralModel.Action) => {
        if ([NOTIFICATIONS_POINTER, GeneralModel.IGNORED_POINTER_ID].includes(action.payload?.pointer)) return state;
        if (![ApiModel.ApiSetDataSections.DATA, null, undefined].includes(action.payload?.section)) return state;
        if (!['global'].includes(action.payload?.scope)) return state;
        return replaceDeep(state, action.payload?.value, action.payload?.pointer);
      })
      // resetting data when view changes
      .addCase(ApiModel.TriggerActionType.DISPATCH_SET_VIEW, () => {
        return {};
      });
  }
});

export type GlobalActions = typeof globalSlice.actions;
export type GlobalStateType = ReturnType<typeof getInitialState>;

export const globalReducer = globalSlice.reducer;
