import type { Coords, CoordsPoint, MultiPolygon, Point, Polygon, Position } from '../types';
import { MarkerType } from '../types';

const isValidLatitude = input => input >= -90 && input <= 90;
const isValidLongitude = input => input >= -180 && input <= 180;
const isValidCoords = (coords: CoordsPoint) => Array.isArray(coords) && isValidLatitude(coords[0]) && isValidLongitude(coords[1]);

const getCoordPoint = (point: Point, flipGeoPoints: boolean): CoordsPoint => {
  let coords: CoordsPoint;
  if (Array.isArray(point)) {
    coords = flipGeoPoints ? [+point[1], +point[0]] : [+point[0], +point[1]];
  } else {
    const lat = (point as any).latitude ?? (point as any).lat;
    const lng = (point as any).longitude ?? (point as any).lng;
    coords = [+lat, +lng];
  }

  if (isValidCoords(coords)) return coords;
};

const getPolygonCoords = (polygon: Polygon | MultiPolygon, flipGeoPoints: boolean, depth = 0): Coords => {
  if (!Array.isArray(polygon)) throw new Error('Not a polygon!');
  if (depth > 1) throw new Error('Too deep!');

  return polygon.map(each => {
    const pointCoords = getCoordPoint(each as Point, flipGeoPoints);
    if (pointCoords) return pointCoords;

    const polygonCoords = getPolygonCoords(each as Polygon, flipGeoPoints, depth + 1);
    return polygonCoords;
  }) as Coords;
};

export const prepareCoords = (position: Position, radius?: number, flipGeoPoints = false): { type: MarkerType; coords: Coords } => {
  const pointCoords = getCoordPoint(position as Point, flipGeoPoints);
  if (pointCoords) {
    return {
      type: radius ? MarkerType.CIRCLE : MarkerType.POINT,
      coords: pointCoords
    };
  }

  try {
    const polygonCoords = getPolygonCoords(position as Polygon, flipGeoPoints);
    if (polygonCoords) {
      return {
        type: MarkerType.POLYGON,
        coords: polygonCoords
      };
    }
  } catch (e) {}

  return { type: MarkerType.INVALID, coords: [] };
};
