import { useLocation } from 'react-router-dom';
import { useViewSelector } from '../../../../../../../../client-app/state-mgmt/view';
import { css } from '@emotion/react';
import { COLOR, GAP } from '@constants';
import { SubItemMenu } from '../../SubItemMenu';
import { useMemo } from 'react';

export const HiddenApp = ({ apps }) => {
  const { pathname, search } = useLocation();

  const globalView = useViewSelector();

  const activeId = new URLSearchParams(search).get('collectionId') || pathname.replace('/', '');
  const isBuilder = pathname.includes('/builder');
  const isApp = pathname.includes('APP_HOME');

  const title = globalView?.navigationInfo?.recordTitle;

  const isAttachedToVisibleApp = useMemo(
    () =>
      Boolean(
        apps.filter(app => {
          const appsView = Boolean(app.views.filter(view => view.id === activeId).length);
          const appsCollection = Boolean(app.collections.filter(collection => collection.id === activeId).length);

          return appsView || appsCollection;
        }).length
      ),
    [apps, activeId]
  );

  const isHidden = isAttachedToVisibleApp || !title || isBuilder || isApp || !activeId;

  if (isHidden) return null;

  return <HiddenEntityRepresentation title={title} />;
};

const HiddenEntityRepresentation = ({ title }) => {
  return (
    <div css={styles.optionContainer}>
      <SubItemMenu.Value title={title} isActive={true} onClick={() => {}} />
    </div>
  );
};

const styles = {
  optionContainer: css`
    padding: 0 ${GAP.XS} ${GAP.XS} ${GAP.XS};
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    border-color: ${COLOR.NEUTRAL_4};
    border-style: solid;
    border-bottom-width: 1px;
  `
};
