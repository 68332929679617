import type { INavigationList, NavigationMenuProps } from '@components/platform/GlobalHeader/types';
import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigationMenuStore } from '../../../../store/NavigationMenuStore';
import { NoSearchResultsPlaceholder } from '../NavigationMenuSearch';
import { Apps } from './components/Apps';
import { Builder } from './components/Builder';
import { DevTools } from './components/DevTools';
import { HiddenApp } from './components/HiddenApp';
import { RecentViews } from './components/RecentViews';
import { BUILDER_ID } from './constants';
import { styles } from './styles';
import { useContextApp } from '../../../Header/components/ViewUsageInApps/useContextApp';

interface NavigationMenuContentProps {
  isOpen: boolean;
  data: INavigationList;
  recentViews: NavigationMenuProps['recentViews'];
  isAdmin: boolean;
}

export const NavigationMenuContent = ({ isOpen, data, recentViews, isAdmin }: NavigationMenuContentProps) => {
  const { pathname } = useLocation();
  const { getContextAppId } = useContextApp();

  const openItem = useNavigationMenuStore(state => state.openItem);
  const isBuilderActive = pathname === '/ADMIN' || pathname.split('/')[1] === 'builder';
  const contextAppId = getContextAppId();

  const getActiveId = useCallback(() => {
    if (isBuilderActive) return BUILDER_ID;
    const activeAppId = data.find(item => isAppActive(item.id))?.id;
    if (activeAppId) return activeAppId;
    return null;
  }, [data, isBuilderActive]);

  useEffect(() => {
    const activeId = getActiveId();

    if (activeId) openItem(activeId);
  }, [openItem, getActiveId]);

  const isAppActive = useCallback((appId: string) => appId === contextAppId, [contextAppId]);

  return (
    <>
      <div css={styles.content} data-testid="AppNavigationWrapper">
        <NoSearchResultsPlaceholder />

        {isAdmin && <Builder isOpen={isOpen} isBuilderActive={isBuilderActive} />}

        {isOpen && <HiddenApp apps={data} />}

        <Apps isOpen={isOpen} data={data} isAppActive={isAppActive} />
      </div>

      <div css={styles.footer}>
        <div css={styles.divider} />
        <RecentViews recentViews={recentViews} isOpen={isOpen} />
        <DevTools isOpen={isOpen} />
      </div>
    </>
  );
};
