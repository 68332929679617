import Skeleton from '@components/elements/Skeleton/Skeleton';
import { CTA, CTAType } from '@components/elements/CTA';
import { PreventClickPropagation } from '@components/elements/PreventClickPropagation';
import { ClientEngineContext, useUnmountObservable, ViewModel } from '@cyferd/client-engine';
import { NavigationSkeletonNewLayout } from './components/NavigationMenuSkeleton';
import { styles } from './styles';
import { NavigationMenuContent } from './components/NavigationMenuContent';
import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { NavigationMenuSearch } from './components/NavigationMenuSearch';
import { useSearchStore } from '../../store/SearchStore';
import { SidebarNavStatus, useNavigationMenuStore } from '../../store/NavigationMenuStore';
import { useGlobalHeaderFetcher } from '@components/platform/GlobalHeader/hooks/useGlobalHeaderFetcher';

// TODO: the app/builder is not highlighted if the its views, collections are selected
export const NavigationMenu = () => {
  const { useUserSelector } = useContext(ClientEngineContext);
  const user = useUserSelector();
  const isAdmin = !!user?.roles?.length;

  const navMenuRef = useRef(null);

  const onDestroy$ = useUnmountObservable();
  const { isLoading, apps, recentViews } = useGlobalHeaderFetcher(onDestroy$);

  const toggleSidebarMode = useNavigationMenuStore(state => state.toggleSidebarMode);
  const isHidden = useNavigationMenuStore(state => state.status === SidebarNavStatus.HIDDEN);
  const isExpanded = useNavigationMenuStore(state => state.status === SidebarNavStatus.EXPANDED);

  const setSearchQuery = useSearchStore(state => state.setSearchQuery);

  useEffect(() => {
    if (isHidden || !isExpanded) setSearchQuery('');
  }, [isHidden, isExpanded, setSearchQuery]);

  const styling = useMemo(
    () => ({
      menu: [styles.menu, !isExpanded && styles.collapsedMenu, isHidden && styles.menuHidden],
      header: [styles.header, !isExpanded && styles.headerCollapsed]
    }),
    [isExpanded, isHidden]
  );

  return (
    <Skeleton loading={isLoading} content={<NavigationSkeletonNewLayout />}>
      <div css={styles.container} ref={navMenuRef} tabIndex={-1}>
        <div css={styling.menu} data-testid="main-nav-content">
          <div css={styling.header}>
            <NavigationMenuSearch navMenuRef={navMenuRef} />

            <PreventClickPropagation>
              <CTA
                testid="AppNavigationWrapper-cta"
                type={CTAType.LINK}
                size={ViewModel.CTASize.LARGE}
                onClick={toggleSidebarMode}
                icon={{ name: isExpanded ? 'keyboard_double_arrow_left' : 'keyboard_double_arrow_right' }}
              />
            </PreventClickPropagation>
          </div>

          <div css={styles.containerTab}>
            <NavigationMenuContent isOpen={isExpanded} data={apps} recentViews={recentViews} isAdmin={isAdmin} />
          </div>
        </div>
      </div>
    </Skeleton>
  );
};
