import { css } from '@emotion/react';
import { FONT_WEIGHT, GAP } from '@constants';

export const styles = {
  container: css`
    width: 100%;
  `,
  searchIconWrapper: css`
    cursor: pointer;
    width: 32px;
    height: 32px;
  `,
  searchInputWrapper: css`
    flex: 1;
    margin-right: 12px;
  `,
  boldSearch: css`
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
  `,
  noSearchPlaceholder: css`
    width: 100%;
    padding: ${GAP.M} ${GAP.L};
  `,
  noSearchParagraph: css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `
};
