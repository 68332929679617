import { createSlice } from '@reduxjs/toolkit';
import { ApiModel, GeneralModel } from '@cyferd/client-engine';
import type { GeneralModel as ClientGeneralModel } from '@models';

export const getInitialState = (): ClientGeneralModel.FavoriteState => ({
  current: null,
  list: null
});

const favoriteSlice = createSlice({
  name: 'favorite',
  initialState: getInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(ApiModel.TriggerActionType.DISPATCH_SET_DATA, (state: ClientGeneralModel.FavoriteState, action: ClientGeneralModel.Action) => {
        if (action.payload?.section !== ApiModel.ApiSetDataSections.FAVORITE || action.payload?.pointer === GeneralModel.IGNORED_POINTER_ID) {
          return state;
        }
        state.list = action.payload.value;
      })
      .addCase(ApiModel.TriggerActionType.DISPATCH_SET_CURRENT_FAVORITE, (state: ClientGeneralModel.FavoriteState, action: ClientGeneralModel.Action) => {
        state.current = action.payload;
      });
  }
});

export type FavoriteActions = typeof favoriteSlice.actions;
export type FavoriteState = ReturnType<typeof getInitialState>;

export const favoriteReducer = favoriteSlice.reducer;
