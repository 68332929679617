import type { ApiModel } from '@cyferd/client-engine';
import { GeneralModel } from '@cyferd/client-engine';
import type { MapMarkerProps, Position } from '../types';
import { MapCenterType, MarkerType } from '../types';
import { useCurrentTheme } from '@components/providers/UIprovider';
import { useEvaluate } from '../../CyTable/hooks/useEvaluate';
import { prepareCoords } from './prepareCoords';

export const useMap = ({
  value,
  mapOptions,
  markerOptions,
  onClick
}: {
  value: ApiModel.ApiValue;
  mapOptions: { centerType: MapCenterType; zoom: number; center: { lat: number; lng: number } };
  markerOptions;
  onClick;
}): { bounds: any; zoom: number; center: [number, number]; markers: MapMarkerProps[] } => {
  const theme = useCurrentTheme();
  const evaluate = useEvaluate();

  const addressFieldId: string = Object.entries((value as ApiModel.ApiValue)?.query?.schema?.properties || {}).find(
    ([, { format }]) => format === GeneralModel.JSONSchemaFormat.ADDRESS
  )?.[0];

  const bounds = [];

  const markers = (value?.list || [])
    .map((record, index) => {
      const {
        title,
        description,
        color,
        icon,
        position,
        radius,
        flipGeoPoints
      }: {
        title: string;
        description: string;
        color: GeneralModel.JSONSchemaFormat.COLOR;
        icon: GeneralModel.JSONSchemaFormat.ICON;
        radius: number;
        position: Position;
        flipGeoPoints: boolean;
      } = evaluate(
        {
          title: markerOptions.title || record.recordTitle,
          description:
            markerOptions.description || record.recordDescription || [record[addressFieldId]?.city, record[addressFieldId]?.country].filter(Boolean).join(', '),
          color: markerOptions.color || record.recordColor,
          icon: markerOptions.icon,
          position:
            markerOptions.position ||
            (addressFieldId && record[addressFieldId]?.lat && record[addressFieldId]?.lng && [record[addressFieldId]?.lat, record[addressFieldId]?.lng]),
          radius: markerOptions.radius,
          flipGeoPoints: markerOptions.flipGeoPoints
        },
        { record }
      );

      const { type, coords } = prepareCoords(position, radius, flipGeoPoints);
      if (type === MarkerType.INVALID) return null;

      // eslint-disable-next-line no-restricted-syntax
      bounds.push(coords);

      const actualColor = (theme[color] || theme.BRAND_1).value;

      return {
        type,
        coords,
        radius,
        title,
        description,
        icon,
        color: actualColor,
        rawColor: color,
        onClick: ({ metaKey, coordinates }) => onClick?.(record, { index, metaKey, coordinates })
      };
    })
    .filter(Boolean);

  return {
    zoom: mapOptions.zoom || 4,
    center: [mapOptions.center?.lat ?? 48, mapOptions.center?.lng ?? 10],
    markers,
    bounds: mapOptions.centerType !== MapCenterType.FIXED && bounds.length ? bounds : undefined
  };
};
