import { memo } from 'react';
import * as Popover from '@radix-ui/react-popover';
import { styles } from './styles';

export interface ItemMenuPopoverProps {
  children: React.ReactNode;
  content: React.ReactNode;
  testid?: string;
  disabled?: boolean;
}

const SIDE_OFFSET = 8;

const ItemMenuPopoverComponent = ({ children, content, testid = 'ItemMenuPopover', disabled }: ItemMenuPopoverProps) => {
  if (disabled || !content) return children;
  return (
    <Popover.Root>
      <Popover.Trigger asChild={true} data-testid={testid}>
        <span>{children}</span>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content side="right" css={styles.main} align="start" sideOffset={SIDE_OFFSET}>
          <Popover.Close css={styles.transparent}>
            <div css={styles.container} data-testid={`${testid}-content`}>
              {content}
            </div>
          </Popover.Close>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

export const ItemMenuPopover = memo(ItemMenuPopoverComponent);
