import { isEditable } from '../constants';

export const parsePastedValue = (value: string, schema: any) => {
  if (!schema?.disabled && isEditable(schema)) {
    if (schema?.type === 'string') return value;
    if (schema?.type === 'number') {
      const number = parseFloat(value);
      if (Number.isNaN(number)) return null;
      return number;
    }
    if (schema?.type === 'boolean') {
      if (value === 'TRUE') return true;
      if (value === 'FALSE') return false;
      return null;
    }
  }
  return null;
};
