import { COLOR, FONT_WEIGHT, GAP, HC_COLOR, HC_SECONDARY_COLOR, RADIUS } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  container: css`
    width: 100%;
    padding: 0 ${GAP.XS};
    height: 40px;
    flex-shrink: 0;
  `,
  optionPrevent: css`
    display: block;
    width: 100%;
  `,
  recordTitle: css`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  `,
  optionContentOpen: css`
    padding: 0 ${GAP.M};
    justify-content: flex-start;
  `,
  optionContentIdle: css`
    color: ${COLOR.NEUTRAL_1};
  `,
  actionContainer: css`
    margin-left: auto;
  `,
  wrapperIconOpen: css`
    width: 32px;
    height: 32px;
  `,
  wrapperIcon: css`
    width: 30px;
    height: 30px;
    border-radius: ${RADIUS.M};
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  chevronWrapper: css`
    &:hover {
      background-color: ${COLOR.NEUTRAL_3_5};
    }
  `,
  activeApp: (color: string) => css`
    background-color: ${COLOR[color] || HC_SECONDARY_COLOR.BRAND_1};
  `,
  hoveredMenuItem: css`
    background-color: ${COLOR.NEUTRAL_4};
  `,
  optionContent: css`
    color: ${COLOR.NEUTRAL_1};
    cursor: pointer;
    border-radius: ${RADIUS.M};
    gap: ${GAP.XS};
    height: 36px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  optionContentActive: css`
    font-weight: ${FONT_WEIGHT.BOLD};
    color: ${HC_COLOR.BRAND_1};
  `,
  openOptionContentActive: css`
    background-color: ${HC_SECONDARY_COLOR.BRAND_1};
  `,
  optionContentHovered: css`
    background-color: ${COLOR.NEUTRAL_4};
  `,
  iconImage: css`
    border-radius: 100%;
    width: 24px;
    height: 24px;
  `,
  activeText: css`
    font-weight: ${FONT_WEIGHT.BOLD};
  `
};
