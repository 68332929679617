/* istanbul ignore file */
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { Connection, Edge } from 'reactflow';
import { ReactFlow, Controls, isEdge, useNodesState, useEdgesState } from 'reactflow';

import 'reactflow/dist/style.css';

import type { FlowModel } from '@cyferd/client-engine';
import { ErrorBoundary } from '@cyferd/client-engine';

import { getElements } from './getElements';
import { ContextMenu } from './components/ContextMenu';
import { SelfConnectingEdge } from './components/SelfConnectingEdge';

export interface FlowChartProps {
  flow: FlowModel.Flow;
  activeStepId: string;
  initialStepId: string;
  onSelect: (stepId: string) => void;
  onChange: (stepKey: string, step: FlowModel.FlowStep) => void;
  onStartAdd: (stepKey: string) => void;
  stepActions: any[];
  edgeActions: any[];
}

export const FlowChart = ({ flow, initialStepId, activeStepId, onSelect, onChange, onStartAdd, stepActions, edgeActions }: FlowChartProps) => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [menu, setMenu] = useState(null);

  useEffect(() => {
    const elements = getElements(flow, activeStepId);

    setNodes(elements.nodes);
    setEdges(elements.edges);
  }, [flow, activeStepId, setNodes, setEdges]);

  const onConnect = useCallback(
    (connection: Edge | Connection) => {
      const { source, target } = connection;

      if (!flow || isEdge(connection) || target === initialStepId) return;

      if (source === initialStepId) onStartAdd(target);
      else onChange(source, { ...flow.steps[source], onResult: [...(flow.steps[source].onResult || []), { goTo: target }] } as FlowModel.FlowStep);
    },
    [initialStepId, onChange, onStartAdd, flow]
  );

  const onNodeClick = useCallback(
    (_, node) => {
      setMenu(null);
      onSelect(node?.id || 'initial-step');
    },
    [onSelect]
  );

  const onEdgeClick = useCallback(
    (_, edge) => {
      onSelect(edge.data?.parentId);
    },
    [onSelect]
  );

  const onContextMenu = useCallback(
    (event, node?) => {
      // Prevent native context menu from showing
      event.preventDefault();

      onSelect(null);
      setMenu({ node, event, actions: node && isEdge(node) ? edgeActions : stepActions });
    },
    [setMenu, onSelect, edgeActions, stepActions]
  );

  // click anywhere
  const onPaneClick = useCallback(() => {
    setMenu(null);
    onSelect(null);
  }, [onSelect]);

  const edgeTypes = useMemo(() => ({ selfconnecting: SelfConnectingEdge }), []);

  return (
    <ErrorBoundary>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        nodesConnectable={true}
        nodesDraggable={false}
        onNodeClick={onNodeClick}
        onEdgeClick={onEdgeClick}
        edgeTypes={edgeTypes}
        onConnect={onConnect}
        snapToGrid={true}
        snapGrid={[20, 20]}
        onPaneContextMenu={onContextMenu}
        onNodeContextMenu={onContextMenu}
        onEdgeContextMenu={onContextMenu}
        onPaneClick={onPaneClick}
      >
        <Controls />
        {menu && <ContextMenu onClick={() => setMenu(null)} menu={menu} setMenu={setMenu} />}
      </ReactFlow>
    </ErrorBoundary>
  );
};
