import type { SearchProps } from '@components/elements/Search';
import { APP_CONTAINER_ID } from '@constants';
import type { Storage } from '@utils';
import { useGetElementSize } from '@utils';
import type { PropsWithChildren } from 'react';
import { useCallback } from 'react';
import { BannerNewLayout } from '../BannerNewLayout';
import { Header } from './components/Header';
import useBannerData from './hooks/useBannerData';
import { styles } from './styles';
import { NavigationMenu } from './components/NavigationMenu';

export interface IMainLayoutConditional extends PropsWithChildren {
  hidden?: boolean;
  searchMenu?: SearchProps;
  hiddenFavorites?: boolean;
  hiddenSearch?: boolean;
  hiddenShortlink?: boolean;
  hiddenNotifications?: boolean;
  hiddenPreferences?: boolean;
  hiddenPrint?: boolean;
  storage?: Storage;
}

export const MainLayoutConditional = ({ children, hidden, ...rest }: IMainLayoutConditional) => {
  return hidden ? <LayoutWithoutHeader>{children}</LayoutWithoutHeader> : <Layout {...rest}>{children}</Layout>;
};

const Layout = ({
  children,
  searchMenu,
  hiddenSearch,
  hiddenShortlink,
  hiddenFavorites,
  hiddenNotifications,
  hiddenPreferences,
  hiddenPrint
}: Omit<IMainLayoutConditional, 'hidden'>) => {
  const { bannerData, isBannerTop, containBannerData } = useBannerData();
  const { ref: bannerRef, height: bannerHeight } = useGetElementSize();

  const renderBanner = useCallback(() => {
    return <BannerNewLayout {...bannerData} ref={bannerRef} />;
  }, [bannerData, bannerRef]);

  return (
    <div data-testid="main-layout" css={styles.mainWrapper}>
      <div css={styles.maxContainer}>
        {containBannerData && <div style={{ order: isBannerTop ? -1 : 1 }}>{renderBanner()}</div>}
        <div css={styles.header}>
          <Header
            searchMenu={searchMenu}
            hiddenSearch={hiddenSearch}
            hiddenShortlink={hiddenShortlink}
            hiddenFavorites={hiddenFavorites}
            hiddenNotifications={hiddenNotifications}
            hiddenPreferences={hiddenPreferences}
            hiddenPrint={hiddenPrint}
            bannerHeight={isBannerTop ? 0 : bannerHeight}
          />
        </div>
        <div css={styles.wrapper}>
          <div css={styles.nav}>
            <NavigationMenu />
          </div>
          <div css={styles.content} id={APP_CONTAINER_ID}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

const LayoutWithoutHeader = ({ children }: PropsWithChildren) => {
  const { bannerData, isBannerTop, containBannerData } = useBannerData();
  return (
    <div data-testid="main-layout-hidden-header" css={styles.mainWrapper}>
      <div css={styles.maxContainer}>
        {containBannerData && <div style={{ order: isBannerTop ? -1 : 1 }}>{<BannerNewLayout {...bannerData} />}</div>}
        <div css={styles.content} id={APP_CONTAINER_ID}>
          {children}
        </div>
      </div>
    </div>
  );
};
